import styles from './ac-panel-footer.module.scss'

import React from 'react'

interface IAcPanelFooter {
  children: React.ReactNode
}

export const AcPanelFooter = ({ children }: IAcPanelFooter) => {
  return <footer className={styles['ac-panel__footer']}>{children}</footer>
}
