import {
  AcAppHeader,
  AcContactCard,
  AcContainer,
  AcGridContainer,
  AcTitle,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useSetDocumentTitle } from '@hooks/use-set-document-title'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { AcDocumentCard } from '../../components/features/ac-document-card/ac-document-card'
import { a, useTrail } from '@react-spring/web'

export const DocumentListView = observer((): JSX.Element => {
  const { documents, account } = useStore()

  const springConfig = {
    mass: 5,
    tension: 2000,
    friction: 250,
    precision: 0.0001,
    clamp: true,
  }

  const documentsTrail = useTrail(documents.all.length, {
    config: springConfig,
    opacity: 0,
    y: 30,
    from: { opacity: 0, y: 30 },
    to: { opacity: 1, y: 0 },
    delay: 200,
  })

  useEffect(() => {
    documents.getList()
    return () => documents.resetOverview()
  }, [])

  useSetDocumentTitle(LABELS.OVERVIEW)

  return (
    <>
      <AcAppHeader withGreeting />
      <AcContainer
        loading={documents.loading}
        padding="none">
        {!documents.hasDocuments && (
          <AcGridContainer columns={{ md: 2, lg: 2 }}>
            <div style={{ marginBottom: '1rem' }}>
              <AcTitle
                level={5}
                withBottomMargin>
                Er zijn nog geen documenten
              </AcTitle>
              <AcTypography>
                Het lijkt erop dat er nog geen (actieve) documenten zijn. Klopt
                dit niet? Neem contact op met jouw Acato contactpersoon.
              </AcTypography>
            </div>
            {account.current_acato_contact && (
              <AcContactCard {...account.current_acato_contact} />
            )}
          </AcGridContainer>
        )}

        {documents.hasDocuments && (
          <>
            <AcTitle
              level={3}
              withBottomMargin>
              {LABELS.DOCUMENTS}
            </AcTitle>
            <AcGridContainer>
              {documentsTrail?.map(({ ...style }, i) => (
                <a.div
                  key={i}
                  style={{ ...style }}>
                  <AcDocumentCard {...documents.all[i]} />
                </a.div>
              ))}
            </AcGridContainer>
          </>
        )}
      </AcContainer>
    </>
  )
})
