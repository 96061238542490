import { AcProjectEmployee } from '@components'
import { IProject } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import styles from './ac-project-employees.module.scss'

export const AcProjectEmployees = observer(
  ({ employees }: Pick<IProject, 'employees' | 'week'>) => {
    const renderEmployees = useMemo(() => {
      return employees && employees?.length
        ? employees.map(singleEmployee => (
            <AcProjectEmployee
              key={singleEmployee.id}
              {...singleEmployee}
            />
          ))
        : '✏️ Er zijn nog geen uren geschreven op dit project'
    }, [employees])

    return (
      <div className={styles['ac-project-employees']}>
        <div className={styles['ac-project-employees-list']}>
          {renderEmployees}
        </div>
      </div>
    )
  }
)
