import clsx from 'clsx'
import styles from './ac-grid-container.module.scss'
import { useMemo } from 'react'

interface IAcGridContainer {
  children: React.ReactNode
  className?: string
  element?: 'div' | 'section'
  columns?: {
    xs?: 1 | 2
    sm?: 1 | 2
    md?: 1 | 2 | 3
    lg?: 1 | 2 | 3
  }
  rowGap?: number
  columnGap?: number
  layout?: '1-2' | '2-1'
  fullWidth?: boolean
  direction?: 'column' | 'row'
}

export const AcGridContainer = ({
  children,
  element: Element = 'section',
  className,
  columns = {
    xs: 1,
    sm: 1,
    md: 3,
    lg: 3,
  },
  rowGap = 1,
  columnGap = 1,
  layout,
  fullWidth,
  direction,
}: IAcGridContainer) => {
  const columnKeys = useMemo(() => Object.keys(columns), [columns])
  const addColumnClasses = (keys: string[]) => {
    const classes = []
    for (const key of keys) {
      classes.push(styles[`ac-grid-container-columns-${key}-${columns[key]}`])
    }
    return classes
  }

  const getGridContainerClasses = useMemo(
    () =>
      clsx(
        styles['ac-grid-container'],
        columnKeys && addColumnClasses(columnKeys),
        layout && styles[`ac-grid-container-layout-${layout}`],
        fullWidth && styles['ac-grid-container--full-width'],
        !!direction && styles[`ac-grid-container--direction-${direction}`],
        className && className
      ),
    [className, columns, layout, fullWidth, direction]
  )
  return (
    <Element
      style={{ rowGap: `${rowGap}rem`, columnGap: `${columnGap}rem` }}
      className={getGridContainerClasses}>
      {children}
    </Element>
  )
}
