import { useEffect, useMemo } from 'react'

export const useSetDocumentTitle = (name?: string) => {
  const base = 'My Acato'
  const title = useMemo(() => {
    if (name) {
      return `${base} - ${name}`
    } else return base
  }, [base, name])
  useEffect(() => {
    document.title = title
  }, [name])
}
