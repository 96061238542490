import { AcTitle, AcTypography } from '../index.core.component'
import styles from './ac-panel-header.module.scss'

interface IAcPanelHeader {
  panelPreTitle?: string
  panelTitle?: string
  panelPreTitlePrimary?: boolean
  panelPreTitleProps?: object
  panelTitleProps?: object
}

export const AcPanelHeader = ({
  panelPreTitle,
  panelTitle,
  panelPreTitleProps = {
    weight: 'light',
    color: 'neutral-200',
  },
  panelTitleProps = {
    level: 5,
  },
}: IAcPanelHeader) => {
  return (
    <header className={styles['ac-panel__header']}>
      <AcTypography
        {...panelPreTitleProps}
        className={styles['ac-panel__pre-title']}>
        {panelPreTitle}
      </AcTypography>
      <AcTitle
        {...panelTitleProps}
        className={styles['ac-panel__title']}>
        {panelTitle}
      </AcTitle>
    </header>
  )
}
