import { AcEmployee, AcTypography } from '@components'
import { LABELS } from '@constants'
import { IProjectEmployeeOverview } from '@typings'
import styles from './ac-project-employee.module.scss'

export const AcProjectEmployee = ({
  name,
  written_hours,
  img,
  role,
}: IProjectEmployeeOverview) => {
  return (
    <div className={styles['ac-project-employee']}>
      <AcEmployee {...{ name, img, role }} />
      <div className={styles['ac-project-employee-hours']}>
        <AcTypography
          weight="semibold"
          size="sm"
          element="span">
          {written_hours}
        </AcTypography>{' '}
        <AcTypography
          color="neutral-200"
          size="sm"
          weight="light"
          element="span">
          {LABELS.HOUR}
        </AcTypography>
      </div>
    </div>
  )
}
