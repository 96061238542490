import React from 'react'
import { AcGridContainer, AcPendingAction, AcTitle } from '@components'
import { LABELS } from '@constants'
import { useStore } from '@hooks/use-store'

export const AcPendingActions = () => {
  const { documents } = useStore()

  if (documents.getPendingDocuments.length < 1) {
    return null
  }

  return (
    <>
      <AcTitle
        level={4}
        withBottomMargin>
        {LABELS.PENDING_ACTIONS}
      </AcTitle>
      <AcGridContainer columns={{ xs: 1 }}>
        {documents.all.map((document, i) => (
          <AcPendingAction
            key={i}
            {...document}
          />
        ))}
      </AcGridContainer>
    </>
  )
}
