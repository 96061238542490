import { LABELS } from '@constants'
import clsx from 'clsx'
import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { AcTextButton } from '../index.core.component'
import styles from './ac-collapsable.module.scss'

interface IAcCollapsableProps {
  children: React.ReactNode
  visibleLines?: number
  className?: string
}

export const AcCollapsable = forwardRef<HTMLDivElement, IAcCollapsableProps>(
  ({
    children,
    className,
    visibleLines = 4,
  }: IAcCollapsableProps): JSX.Element => {
    const collapseRef = useRef<HTMLDivElement>()
    const collapsible = useRef<HTMLDivElement>()
    const needsButton = useCallback(
      () =>
        collapsible.current?.getBoundingClientRect().height >
        collapseRef.current?.getBoundingClientRect().height,
      [collapseRef, collapsible]
    )
    const [collapsed, setCollapsed] = useState<boolean>(true)

    const toggleContent = () => {
      setCollapsed(!collapsed)
    }

    const getCollapsableClasses = useMemo(
      () =>
        clsx(
          styles['ac-collapsable'],
          collapsed && styles['ac-collapsable--collapsed'],
          className && className
        ),
      [collapsed]
    )

    return (
      <div
        ref={collapseRef}
        className={getCollapsableClasses}>
        <div
          className={styles['ac-collapsable-content']}
          style={{ WebkitLineClamp: visibleLines }}
          ref={collapsible}>
          {children}
        </div>
        {needsButton() && (
          <AcTextButton
            withTooltip={false}
            label={collapsed ? LABELS.READ_MORE : LABELS.READ_LESS}
            onClick={() => toggleContent()}
          />
        )}
      </div>
    )
  }
)

AcCollapsable.displayName = 'AcCollapsable'
