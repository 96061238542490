import { AcContainer } from '@components'
import { LABELS, ROUTE_PATHS } from '@constants'
import { useSetDocumentTitle } from '@hooks/use-set-document-title'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'

export const LoginWithToken = observer(() => {
  const [loading, setLoading] = useState(true)
  const [params] = useSearchParams()
  const token = params.get('token')
  const contactId = parseInt(params.get('contactId'))
  const { auth } = useStore()

  const requestToken = async ({ token, contactId }) => {
    return await auth
      .requestToken({ token, contactId })
      .then(() => setTimeout(() => setLoading(false), 2000))
  }

  useEffect(() => {
    if (token && contactId) {
      requestToken({ token, contactId })
    }
  }, [])

  useSetDocumentTitle(LABELS.LOGIN)

  return (
    <AcContainer loading={loading}>
      <Navigate
        to={ROUTE_PATHS.HOME}
        replace
      />
    </AcContainer>
  )
})
