/* eslint-disable max-len */
import { STORES, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { IProject, IProjectList, IProjectPlanning } from '@typings'
import { action, computed, makeAutoObservable } from 'mobx'

const defaultValues = {
  loading: true,
  all: [],
  single: {} as IProject,
  planning: {} as IProjectPlanning,
  planningLoading: true,
}

export class ProjectStore {
  [key: string]: unknown
  store: Store
  loading: boolean
  all: IProjectList[]
  single: IProject
  planning: IProjectPlanning
  planningLoading: boolean

  constructor(store: Store) {
    this.store = store
    this.loading = defaultValues.loading
    this.all = defaultValues.all
    this.single = defaultValues.single
    this.planning = defaultValues.planning
    this.planningLoading = defaultValues.planningLoading
    makeAutoObservable(this)
  }

  @computed
  get hasProjects(): boolean {
    return this.all && !!this.all.length
  }

  @computed
  get current_week(): IProject['week']['current'] {
    return this.single?.week?.current
  }

  @action
  getList = async () => {
    this.store.set(STORES.PROJECTS, STORE_KEYS.LOADING, true)
    await this.store.api.projects
      .getList()
      .then(res => {
        this.store.set(STORES.PROJECTS, STORE_KEYS.ALL, res.data)
      })
      .catch(e => console.error(e))
      .finally(() => {
        this.store.set(STORES.PROJECTS, STORE_KEYS.LOADING, false)
      })
  }

  @action
  getSingleProjectPlanning = async (slug: IProject['slug']) => {
    this.store.set(STORES.PROJECTS, STORE_KEYS.PLANNING_LOADING, true)
    await this.store.api.projects
      .getDetailPlanning(slug)
      .then(res =>
        this.store.set(STORES.PROJECTS, STORE_KEYS.PLANNING, res.data)
      )
      .catch(e => console.error(e))
      .finally(() =>
        this.store.set(STORES.PROJECTS, STORE_KEYS.PLANNING_LOADING, false)
      )
  }

  @action
  getSingle = async (slug: IProject['slug']) => {
    this.store.set(STORES.PROJECTS, STORE_KEYS.LOADING, true)
    await this.getSingleProjectPlanning(slug)
    await this.store.api.projects
      .getDetail(slug)
      .then(res => {
        this.store.set(STORES.PROJECTS, STORE_KEYS.SINGLE, res.data)
      })
      .catch(e => console.error(e))
      .finally(() => {
        this.store.set(STORES.PROJECTS, STORE_KEYS.LOADING, false)
      })
  }

  @action
  resetOverview = () => {
    const resetFields = [STORE_KEYS.LOADING, STORE_KEYS.ALL]

    for (const field of resetFields) {
      this.store.set(STORES.PROJECTS, field, defaultValues[field])
    }
  }

  @action
  resetSingle = () => {
    const resetFields = [
      STORE_KEYS.LOADING,
      STORE_KEYS.SINGLE,
      STORE_KEYS.PLANNING,
      STORE_KEYS.PLANNING_LOADING,
    ]

    for (const field of resetFields) {
      this.store.set(STORES.PROJECTS, field, defaultValues[field])
    }
  }
}
