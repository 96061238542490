import { ENDPOINTS } from '@constants'
import {
  IAuthRequestEmailResponse,
  IAuthRequestTokenData,
  IAuthRequestTokenResponse,
} from '@typings'
import { AxiosResponse } from 'axios'
import { BaseApi } from './_base.api'

export class AuthApi extends BaseApi {
  requestToken = async (data: IAuthRequestTokenData) =>
    await this.api
      .post<
        AxiosResponse<{ message: string; data: IAuthRequestTokenResponse }>
      >(ENDPOINTS.AUTH.TOKEN, { ...data })
      .then(response => response.data)

  requestEmail = (email: string) =>
    this.api
      .post<AxiosResponse<IAuthRequestEmailResponse>>(ENDPOINTS.AUTH.EMAIL, {
        email,
      })
      .then(response => response.data)

  logout = () =>
    this.api.get<AxiosResponse>(ENDPOINTS.AUTH.LOGOUT).then(res => res.data)
}
