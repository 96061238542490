import React from 'react'
import { AcPanel } from '../../core/ac-panel/ac-panel'
import { AcTypography } from '../../core/ac-typography/ac-typography'
import { AcTitle } from '../../core/ac-title/ac-title'
import { generatePath, useNavigate } from 'react-router-dom'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { AcTextButton } from '../../core/ac-text-button/ac-text-button'
import { AcGridContainer } from '../../core/ac-panels/ac-grid-container'
import { AcPill } from '../../core/ac-pill/ac-pill'
import { IDocumentList } from '@typings'

export const AcPendingAction = ({ title, description, id }: IDocumentList) => {
  const navigate = useNavigate()
  return (
    <AcPanel>
      <AcGridContainer
        columns={{ xs: 2 }}
        fullWidth>
        <AcTitle level={5}>{title}</AcTitle>
        <AcTypography alignment="right">
          <AcPill>{LABELS.PENDING}</AcPill>
        </AcTypography>
      </AcGridContainer>
      <AcTypography
        weight="light"
        color="neutral-200">
        {description}
      </AcTypography>

      <AcTextButton
        label="Teken overeenkomst"
        icon={ICONS.ARROW_RIGHT}
        iconPosition="after"
        onClick={() =>
          navigate(generatePath(ROUTE_PATHS.DOCUMENT_DETAIL, { id }))
        }
      />
    </AcPanel>
  )
}
