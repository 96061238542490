import React from 'react'
import clsx from 'clsx'
import styles from './ac-note.module.scss'
import { AcTypography } from '../ac-typography/ac-typography'
import { AcIcon } from '../ac-icon/ac-icon'
import { ICONS } from '@constants'

interface IAcNote {
  content: string
  variant: 'error' | 'warning' | 'notice'
  hidden?: boolean
}

export const AcNote = ({ content, variant, hidden }: IAcNote) => {
  const classes = clsx(
    styles['ac-note'],
    variant && styles[`ac-note--${variant}`],
    hidden && styles['ac-note--hidden']
  )

  return (
    <span
      className={classes}
      aria-hidden={hidden}>
      <AcIcon icon={ICONS.ALERT} />
      <AcTypography
        weight="bold"
        size="sm">
        {content}
      </AcTypography>
    </span>
  )
}
