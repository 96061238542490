import { INavItem } from '@typings'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { AcIcon, AcTooltip } from '../index.core.component'
import styles from './ac-navigation-item.module.scss'

export const AcNavigationItem = ({ to, icon, label, ...rest }: INavItem) => {
  const getNavlinkClasses = (active: boolean) =>
    clsx(
      styles['ac-navigation__item'],
      active && styles['ac-navigation__item--active']
    )
  return (
    <AcTooltip label={label}>
      <NavLink
        className={({ isActive }) => getNavlinkClasses(isActive)}
        to={to}
        {...rest}>
        {icon && (
          <AcIcon
            icon={icon}
            size="md"
            className={styles['ac-navigation__item__icon']}
          />
        )}
      </NavLink>
    </AcTooltip>
  )
}
