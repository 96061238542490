import React from 'react'
import { DYNAMIC_LABELS, LABELS } from '@constants'
import { AcPill } from '../../core/ac-pill/ac-pill'
import { AcSummaryItem } from '../ac-summary-item/ac-summary-item'
import { formatDate } from '@helpers/format-date.helpers'
import { IDocument } from '@typings'
import { AcGridContainer } from '../../core/ac-panels/ac-grid-container'

export const AcDocumentDetails = ({
  id,
  expires_at,
  created_at,
  signed_at,
}: IDocument) => {
  return (
    <AcGridContainer
      rowGap={0.4}
      fullWidth
      columns={{ xs: 1 }}>
      <AcSummaryItem
        label={LABELS.SENDER}
        value="Acato B.V."
      />

      <AcSummaryItem
        label={LABELS.CUSTOMER_NUMBER}
        value={id}
      />
      <AcSummaryItem
        label={LABELS.DATE_QUOTATION}
        value={formatDate(created_at)}
      />

      <AcSummaryItem
        label={LABELS.DATE_EXPIRY}
        value={formatDate(expires_at)}
      />

      <AcSummaryItem
        label={LABELS.SIGNED}
        value={
          <AcPill size="sm">{DYNAMIC_LABELS.SIGNED_STATUS(!!signed_at)}</AcPill>
        }
      />
    </AcGridContainer>
  )
}
