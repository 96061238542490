import { BaseApi } from './_base.api'
import { ENDPOINTS } from '@constants'
import { IDocumentListResponse, IDocumentList, IDocument } from '@typings'
import { AxiosResponse } from 'axios'

export class DocumentsApi extends BaseApi {
  getList = () =>
    this.api
      .get<AxiosResponse<IDocumentListResponse>>(ENDPOINTS.DOCUMENTS.LIST)
      .then(response => response.data)

  getDetail = (id: IDocumentList['id']) =>
    this.api
      .get<AxiosResponse<{ data: IDocument }>>(ENDPOINTS.DOCUMENTS.DETAIL(id))
      .then(response => response.data)

  sign = (id: IDocumentList['id']) =>
    this.api
      .put<AxiosResponse<{ data: object }>>(ENDPOINTS.DOCUMENTS.SIGN(id), {
        agree: true,
      })
      .then(response => response.data)

  downloadDocument = (id: IDocumentList['id']) =>
    this.api
      .get<AxiosResponse<BlobPart>>(ENDPOINTS.DOCUMENTS.DOWNLOAD(id), {
        responseType: 'blob',
      })
      .then(response => response.data)

  downloadAttachment = (id: IDocumentList['id']) =>
    this.api
      .get<AxiosResponse<BlobPart>>(ENDPOINTS.DOCUMENTS.ATTACHMENT(id))
      .then(response => response.data)
}
