import React from 'react'
import { AcGridContainer, AcTypography } from '@components'

interface IACSummaryItem {
  label?: string
  value?: string | React.ReactNode
}

export const AcSummaryItem = ({ label, value }: IACSummaryItem) => {
  return (
    <AcGridContainer
      direction="row"
      columns={{ xs: 2 }}
      fullWidth>
      <AcTypography
        weight="bold"
        color="neutral-200"
        size="sm">
        {label}
      </AcTypography>
      <AcTypography
        weight="bold"
        size="sm">
        {value}
      </AcTypography>
    </AcGridContainer>
  )
}
