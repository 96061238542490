import reportWebVitals from '@helpers/report-web-vitals.helper'
import { render } from 'preact'
import 'preact/debug'
import React from 'react'
import { AcRouter } from './components/core/index.core.component'
import '@styles/index.styles.scss'

const root = document.getElementById('root') as HTMLElement

render(
  <React.StrictMode>
    <div>
      <AcRouter />
    </div>
  </React.StrictMode>,
  root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
