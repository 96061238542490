import { IProjectHours } from '@typings'
import styles from './ac-project-hours.module.scss'
import { DYNAMIC_LABELS, LABELS } from '@constants'
import { useMemo } from 'react'
import clsx from 'clsx'
import { useColorFromHours } from '@hooks/use-color-from-hours'

interface IAcProjectHours extends IProjectHours {
  color?: 'primary' | 'secondary' | 'tertiary'
}

export const AcProjectHours = ({
  total_available,
  planned,
  written,
  remaining,
  color,
}: IAcProjectHours) => {
  const colorVar = useColorFromHours({
    written,
    planned,
    total_available,
    remaining,
  })

  const calcProgress = (used: number, total: number) => {
    const width = (used / total) * 100
    if (width > 100) {
      return { width: '100%' }
    }
    return { width: `${width}%` }
  }

  const getWrapperClasses = useMemo(
    () =>
      clsx(
        styles['ac-project-hours'],
        color
          ? styles[`ac-project-hours--${color}`]
          : styles[`ac-project-hours--${colorVar}`]
      ),
    [color, colorVar]
  )

  return (
    <div className={getWrapperClasses}>
      <div className={styles['ac-project-hours__hours-overview']}>
        <div className={styles['ac-project-hours__total']}>
          <span>
            <strong>{written}</strong> / {total_available}
          </span>
          {` ${LABELS.HOURS_TOTAL}`}
        </div>

        <div className={styles['ac-project-hours__progressbar']}>
          <span className={styles['ac-project-hours__progressbar-open']}></span>
          <span
            className={styles['ac-project-hours__progressbar-planned']}
            style={calcProgress(planned + written, total_available)}></span>
          <span
            className={styles['ac-project-hours__progressbar-used']}
            style={calcProgress(written, total_available)}></span>
        </div>
      </div>
      <div className={styles['ac-project-hours__legend']}>
        <div className={styles['ac-project-hours__legend-item']}>
          <div className={styles['ac-project-hours__legend-item__content']}>
            <span
              className={`
              ${styles['ac-project-hours__legend-item__content__dot']} 
              ${styles['ac-project-hours__legend-item__content__dot_first']}`}
            />
            <span
              className={
                styles['ac-project-hours__legend-item__content__label']
              }>
              {LABELS.WRITTEN}
            </span>
          </div>
          <div
            className={styles['ac-project-hours__legend-item__content__hours']}>
            {DYNAMIC_LABELS.N_HOUR(written)}
          </div>
        </div>
        <div className={styles['ac-project-hours__legend-item']}>
          <div className={styles['ac-project-hours__legend-item__content']}>
            <span
              className={`
              ${styles['ac-project-hours__legend-item__content__dot']} 
              ${styles['ac-project-hours__legend-item__content__dot_second']}`}
            />
            <span
              className={
                styles['ac-project-hours__legend-item__content__label']
              }>
              {LABELS.PLANNED}
            </span>
          </div>
          <div
            className={styles['ac-project-hours__legend-item__content__hours']}>
            {DYNAMIC_LABELS.N_HOUR(planned)}
          </div>
        </div>
        <div className={styles['ac-project-hours__legend-item']}>
          <div className={styles['ac-project-hours__legend-item__content']}>
            <span
              className={`
              ${styles['ac-project-hours__legend-item__content__dot']} 
              ${styles['ac-project-hours__legend-item__content__dot_third']}`}
            />
            <span
              className={
                styles['ac-project-hours__legend-item__content__label']
              }>
              {LABELS.HOURS_OPEN}
            </span>
          </div>
          <div
            className={styles['ac-project-hours__legend-item__content__hours']}>
            {DYNAMIC_LABELS.N_HOUR(remaining)}
          </div>
        </div>
      </div>
    </div>
  )
}
