import {
  AcButton,
  AcInput,
  AcLoader,
  AcPanel,
  AcTitle,
  AcTypography,
} from '@components'
import { LABELS, ROUTE_PATHS } from '@constants'
import { isValidEmail } from '@helpers/validator.helpers'
import { useSetDocumentTitle } from '@hooks/use-set-document-title'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import logo from '@assets/images/Logo.svg'
import styles from './login.module.scss'

export const LoginView = observer(() => {
  const [email, setEmail] = useState<string>()
  const [error, setError] = useState<string>()
  const [submitted, setSubmitted] = useState(false)
  const [message, setMessage] = useState<string>()
  const { auth } = useStore()

  const handleLogin = async event => {
    event.preventDefault()
    return await auth.requestEmail(email).then(res => {
      setMessage(res as string)
      setSubmitted(true)
    })
  }

  if (auth.is_authorized) return <Navigate to={ROUTE_PATHS.HOME} />

  useSetDocumentTitle(LABELS.LOGIN)

  useEffect(() => {
    if (email && !isValidEmail(email)) {
      setError('Geen geldig e-mail adres')
    } else if (isValidEmail(email) || !email) {
      setError(null)
    }
  }, [email])

  return (
    <div className={styles['ac-login']}>
      <div className={styles['ac-login-logo-container']}>
        <img
          src={logo}
          className={styles['ac-login-logo']}
        />
      </div>
      <section className={styles['ac-login-panel-container']}>
        {auth.message && (
          <div className={styles['ac-login-panel-error']}>
            <AcTypography>{auth.message}</AcTypography>
          </div>
        )}
        <AcPanel className={styles['ac-login-panel']}>
          <span>
            <AcTitle level={4}>{LABELS.ACATO_CLIENT_PORTAL}</AcTitle>
            <AcTypography>Grip op jouw projecten via My Acato</AcTypography>
          </span>
          <form
            className={styles['ac-login-form']}
            onSubmit={handleLogin}>
            {submitted && (
              <AcTypography color="neutral-200">{message}</AcTypography>
            )}
            {!submitted &&
              (auth.loading ? (
                <AcLoader />
              ) : (
                <>
                  <AcInput
                    name="email"
                    type="email"
                    onChange={e => setEmail(e.target.value)}
                    placeholder="jouwnaam@jouwbedrijf.nl"
                    label={'E-mail'}
                    error={error}
                  />
                  <AcButton
                    type="submit"
                    label={LABELS.LOGIN}
                    fullWidth
                    disabled={!!error || !email}
                    withToolTip={false}
                  />
                </>
              ))}
          </form>
        </AcPanel>
      </section>
    </div>
  )
})
