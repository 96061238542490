import { AcTypography } from '@components'
import { IEmployee } from '@typings'
import styles from './ac-employee.module.scss'
import { useMemo } from 'react'
import clsx from 'clsx'

interface IAcEmployee extends Pick<IEmployee, 'name' | 'role' | 'img'> {
  color?: 'default' | 'primary' | 'secondary' | 'tertiary'
}

export const AcEmployee = ({
  name,
  role,
  img,
  color = 'default',
}: IAcEmployee) => {
  const getImageClasses = useMemo(
    () =>
      clsx(styles['ac-employee-image'], styles[`ac-employee-image--${color}`]),
    [color]
  )
  return (
    <div className={styles['ac-employee']}>
      <div className={styles['ac-employee-image-wrapper']}>
        <div
          className={getImageClasses}
          style={{ backgroundImage: `url(${img.sm})` }}
        />
      </div>
      <div className={styles['ac-employee-info']}>
        <AcTypography
          size="sm"
          weight="semibold">
          {name}
        </AcTypography>
        <AcTypography
          color="neutral-200"
          element="span"
          size="xs"
          weight="light">
          {role}
        </AcTypography>
      </div>
    </div>
  )
}
