import { STORES, STORE_KEYS } from '@constants'
import { autoLoad, removeFromStorage } from '@helpers/browser-storage.helpers'
import { Store } from '@stores'
import { IUser } from '@typings'
import { action, makeAutoObservable } from 'mobx'

interface IAuthDefaultValues {
  [key: string]: unknown
  user: Nullable<IUser>
  loading: boolean
}

const defaultValues = {
  user: null as IUser,
  loading: true,
}

export class AccountStore {
  [key: string]: unknown
  store: Store
  user: IAuthDefaultValues['user']
  loading: boolean

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.user = defaultValues[STORE_KEYS.USER]
    this.loading = defaultValues[STORE_KEYS.LOADING]
    autoLoad(this, STORE_KEYS.USER)
  }

  get current_user_full_name() {
    return this.user ? `${this.user?.first_name} ${this.user?.last_name}` : ''
  }

  get current_user(): IUser {
    return this.user ?? null
  }

  get current_user_name(): IUser['first_name'] {
    return this.user?.first_name ?? ''
  }

  get current_acato_contact(): IUser['acato_contact'] {
    return this.user?.acato_contact
  }

  @action
  getUser = async () => {
    this.store.set(STORES.ACCOUNT, STORE_KEYS.LOADING, true)
    await this.store.api.account
      .getUser()
      .then(response => {
        this.store.set(STORES.ACCOUNT, STORE_KEYS.USER, response, true)
      })
      .catch(e => console.error(e))
      .finally(() => {
        this.store.set(STORES.ACCOUNT, STORE_KEYS.LOADING, false)
      })
  }

  @action
  setUser = (user: IUser) => {
    this.store.set(STORES.ACCOUNT, STORE_KEYS.LOADING, true)
    setTimeout(() => {
      this.store.set(STORES.ACCOUNT, STORE_KEYS.USER, user)
      this.store.set(STORES.ACCOUNT, STORE_KEYS.LOADING, false)
    })
  }

  @action
  clearUser = () => {
    this.store.set(STORES.ACCOUNT, STORE_KEYS.LOADING, true)
    this.setUser(defaultValues[STORE_KEYS.USER])
    removeFromStorage(STORE_KEYS.USER)
  }
}
