import {
  AcAppHeader,
  AcContactCard,
  AcContainer,
  AcGridContainer,
  AcPanel,
  AcPanelHeader,
  AcTitle,
  AcTypography,
} from '@components'
import { LABELS } from '@constants'
import { useSetDocumentTitle } from '@hooks/use-set-document-title'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const AccountView = observer(() => {
  const navigate = useNavigate()
  const { account } = useStore()

  useEffect(() => {
    account.getUser()
  }, [])

  useSetDocumentTitle(LABELS.ACCOUNT)

  return (
    <>
      <AcAppHeader backAction={() => navigate(-1)} />
      <AcContainer
        padding="none"
        loading={account.loading}>
        <AcTitle
          level={4}
          withBottomMargin>
          {LABELS.ACCOUNT}
        </AcTitle>
        <AcGridContainer
          columns={{ xs: 1, sm: 1, md: 2, lg: 2 }}
          layout="2-1">
          <AcPanel>
            <AcPanelHeader panelTitle={LABELS.CONTACT_INFO}></AcPanelHeader>
            <div>
              <AcTypography
                size="sm"
                color="neutral-200">
                {LABELS.COMPANY}
              </AcTypography>
              <AcTypography>{account.user?.customer.name}</AcTypography>
            </div>
            <div>
              <AcTypography
                size="sm"
                color="neutral-200">
                {LABELS.NAME}
              </AcTypography>
              <AcTypography>{account.current_user_full_name}</AcTypography>
            </div>
            <div>
              <AcTypography
                size="sm"
                color="neutral-200">
                {LABELS.EMAIL}
              </AcTypography>
              <AcTypography>{account.user?.email}</AcTypography>
            </div>
            <div>
              <AcTypography
                size="sm"
                color="neutral-200">
                {LABELS.PHONE}
              </AcTypography>
              <AcTypography>{account.user?.phone}</AcTypography>
            </div>
          </AcPanel>
          <AcGridContainer columns={{ xs: 1, sm: 1, md: 1, lg: 1 }}>
            <AcPanel>
              <AcPanelHeader panelTitle={`${LABELS.CHANGES}?`}></AcPanelHeader>
              <AcTypography weight="light">
                Kloppen je gegevens niet? Geef het aan bij je contact-persoon
                bij Acato. Dan passen wij het voor je aan.{' '}
              </AcTypography>
            </AcPanel>
            <AcContactCard {...account.current_acato_contact} />
          </AcGridContainer>
        </AcGridContainer>
      </AcContainer>
    </>
  )
})
