import {
  AccountView,
  LoginView,
  LoginWithToken,
  ProjectDetailView,
  ProjectListView,
  DocumentListView,
  DocumentDetailView,
} from '@views'
import { AcLayoutAuthenticated, AcLayoutGuest } from 'src/layouts/index.layouts'
import { KEYS } from './keys.contants'

export const ROUTE_PATHS = {
  HOME: '/',
  PROJECT_DETAIL: '/project/:slug',
  EXAMPLE: '/example',
  ACCOUNT: '/account',
  LOGIN: '/login',
  LOGIN_WITH_TOKEN: '/loginwithtoken',
  DOCUMENTS: '/document',
  DOCUMENT_DETAIL: '/document/:id',
}

export const ROUTES = [
  {
    id: KEYS.HOME,
    path: ROUTE_PATHS.HOME,
    element: <ProjectListView />,
    layout: <AcLayoutAuthenticated />,
    allowed: [],
  },
  {
    id: KEYS.PROJECT_DETAIL,
    path: ROUTE_PATHS.PROJECT_DETAIL,
    element: <ProjectDetailView />,
    layout: <AcLayoutAuthenticated />,
    allowed: [],
  },
  {
    id: KEYS.ACCOUNT,
    path: ROUTE_PATHS.ACCOUNT,
    element: <AccountView />,
    layout: <AcLayoutAuthenticated />,
    allowed: [],
  },
  {
    id: KEYS.LOGIN,
    path: ROUTE_PATHS.LOGIN,
    element: <LoginView />,
    layout: <AcLayoutGuest />,
  },
  {
    id: KEYS.LOGIN_WITH_TOKEN,
    path: ROUTE_PATHS.LOGIN_WITH_TOKEN,
    element: <LoginWithToken />,
    layout: <AcLayoutGuest />,
  },
  {
    id: KEYS.DOCUMENTS,
    path: ROUTE_PATHS.DOCUMENTS,
    element: <DocumentListView />,
    layout: <AcLayoutAuthenticated />,
  },
  {
    id: KEYS.DOCUMENT_DETAIL,
    path: ROUTE_PATHS.DOCUMENT_DETAIL,
    element: <DocumentDetailView />,
    layout: <AcLayoutAuthenticated />,
  },
]
