import {
  AcAppHeader,
  AcContainer,
  AcGridContainer,
  AcPanel,
  AcPanelHeader,
  AcProjectEmployees,
  AcProjectGeneral,
  AcProjectHours,
  AcProjectPlanning,
  AcTitle,
  AcTypography,
} from '@components'
import { DYNAMIC_LABELS, LABELS, ROUTE_PATHS } from '@constants'
import { useSetDocumentTitle } from '@hooks/use-set-document-title'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const ProjectDetailView = observer((): JSX.Element => {
  const { projects } = useStore()
  const { slug } = useParams()
  const navigate = useNavigate()
  const handleBackAction = () => {
    return navigate(ROUTE_PATHS.HOME)
  }

  useEffect(() => {
    projects.getSingle(slug)
    return () => projects.resetSingle()
  }, [])

  useSetDocumentTitle(projects?.single?.name)

  return (
    <>
      <AcAppHeader
        backAction={handleBackAction}
        backActionLabel={LABELS.BACK_TO_OVERVIEW}
      />
      <AcContainer
        loading={projects.loading}
        padding="none">
        <AcTitle
          level={4}
          withBottomMargin>
          {projects.single.name}
        </AcTitle>
        <AcGridContainer
          columns={{ md: 1, lg: 1 }}
          rowGap={1.5}>
          <AcGridContainer columns={{ md: 2, lg: 2 }}>
            <AcPanel>
              <AcPanelHeader panelTitle={LABELS.GENERAL} />
              <AcProjectGeneral {...projects.single} />
              <AcPanelHeader panelTitle={LABELS.DESCRIPTION}></AcPanelHeader>
              <AcTypography html={projects.single?.description} />
            </AcPanel>
            <AcGridContainer columns={{ md: 1, lg: 1 }}>
              <AcPanel>
                <AcPanelHeader panelTitle={LABELS.OVERVIEW} />
                <AcProjectHours {...projects.single?.hours} />
              </AcPanel>
              <AcPanel>
                <AcPanelHeader
                  panelTitle={DYNAMIC_LABELS.UNTILL_WEEK_N(
                    projects.current_week - 1
                  )}
                  panelPreTitle={LABELS.WRITTEN_HOURS}
                />
                <AcProjectEmployees {...projects.single} />
              </AcPanel>
            </AcGridContainer>
          </AcGridContainer>
          <AcProjectPlanning
            {...projects.planning}
            currentWeek={projects?.single?.week?.current}
            loading={projects.planningLoading}
          />
        </AcGridContainer>
      </AcContainer>
    </>
  )
})
