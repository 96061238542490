import clsx from 'clsx'
import { useMemo } from 'react'
import styles from './ac-typography.module.scss'

interface IAcTypography {
  size?: 'md' | 'sm' | 'xs'
  weight?: 'bold' | 'semibold' | 'regular' | 'light'
  className?: string
  element?: 'p' | 'span' | 'legend'
  color?: 'neutral-200' | 'primary'
  alignment?: 'left' | 'center' | 'right'
  whitespace?: 'pre-wrap' | 'pre-line'
}
interface IAcTypographyHTMLFromChildren extends IAcTypography {
  html?: never
  children: React.ReactNode
}
interface IAcTypographyHTMLFromString extends IAcTypography {
  children?: never
  html: string
}

export const AcTypography = ({
  children,
  html,
  size = 'md',
  weight = 'regular',
  alignment = 'left',
  className,
  whitespace,
  element: Element = 'p',
  color,
}: IAcTypographyHTMLFromString | IAcTypographyHTMLFromChildren) => {
  const getTypographyClassNames = useMemo(
    () =>
      clsx(
        styles['ac-typography'],
        styles[`ac-typography--${Element}`],
        styles[`ac-typography--${size}`],
        styles[`ac-typography--${weight}`],
        color && styles[`ac-typography--color-${color}`],
        alignment && styles[`ac-typography--alignment-${alignment}`],
        alignment && styles[`ac-typography--whitespace-${whitespace}`],
        className && className
      ),
    [Element, size, weight, color]
  )

  return (
    <Element
      className={getTypographyClassNames}
      dangerouslySetInnerHTML={html ? { __html: html } : void 0}>
      {children}
    </Element>
  )
}
