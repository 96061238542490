import {
  AcAppHeader,
  AcButton,
  AcConfirm,
  AcContactCard,
  AcContainer,
  AcDocumentDetails,
  AcGridContainer,
  AcPanel,
  AcTitle,
  AcTypography,
} from '@components'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetDocumentTitle } from '@hooks/use-set-document-title'
import { useEffect } from 'react'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'

import styles from './document-detail.module.scss'
import { IDocument } from '@typings'
import { formatDate } from '@helpers/format-date.helpers'
import { formatCustomerContactName } from '@helpers/format-customer-contact-name.helpers'

export const DocumentDetailView = observer((): JSX.Element => {
  const navigate = useNavigate()
  const { account, documents } = useStore()
  const { id } = useParams()

  const handleBackAction = () => {
    return navigate(ROUTE_PATHS.HOME)
  }

  useSetDocumentTitle(LABELS.DOCUMENTS)

  useEffect(() => {
    documents.getSingle(parseInt(id))
    return () => documents.resetSingle()
  }, [])

  const signDocument = async (id: IDocument['id']) => {
    const message = await documents.sign(id)
    console.log(message)
  }

  const downloadDocument = async (id: IDocument['id']) => {
    await documents.downloadDocument(id)
  }

  const downloadAttachment = async (id: IDocument['id']) => {
    await documents.downloadAttachment(id)
  }

  return (
    <div className={styles['ac-document-detail']}>
      <AcAppHeader
        backAction={handleBackAction}
        backActionLabel={LABELS.BACK_TO_OVERVIEW}
      />

      <AcContainer
        loading={documents.loading}
        padding="none">
        <AcTitle
          level={4}
          withBottomMargin>
          {documents.single.title ?? LABELS.DOCUMENT}
        </AcTitle>

        <AcGridContainer
          layout="2-1"
          columns={{ xs: 1, md: 2 }}>
          <AcPanel mode="light">
            <AcGridContainer
              columns={{ xs: 2 }}
              fullWidth>
              <AcTypography
                weight="bold"
                className={styles['ac-document-detail__address']}>
                {documents.single.company?.name} <br />
                {LABELS.WITH_ATTENTION_TO} {account.current_user_full_name}
                <br />
                {documents.single.company?.visitingaddress_street}{' '}
                {documents.single.company?.visitingaddress_streetnumber} <br />
                {documents.single.company?.visitingaddress_zipcode}{' '}
                {documents.single.company?.visitingaddress_city} <br />
                {documents.single.company?.kvk}
              </AcTypography>
              {documents.single.signed_at && (
                <AcTypography
                  className={styles['ac-document-detail__download']}>
                  <AcButton
                    onClick={() => downloadDocument(parseInt(id))}
                    icon={ICONS.DOWNLOAD}
                    iconPosition="before"
                    label={LABELS.DOWNLOAD}
                    color="secondary"
                    mode="dark"
                    alignment="right"
                  />
                </AcTypography>
              )}
            </AcGridContainer>

            {/* eslint-disable max-len */}
            {/* eslint-disable react/no-unescaped-entities */}
            <AcTypography
              weight="light"
              whitespace="pre-wrap">
              Voor een site of webapplicatie waarbij wij als verwerker toegang hebben tot persoonsgegevens sluiten wij met elkaar een verwerkersovereenkomst af.
              <br />
              <br />
              Partijen
              <br />
              <br />
              De partij die in de gegevens hierboven wordt aangeduid; hierna te noemen "Verwerkingsverantwoordelijke"
              <br />
              <br />
              -en-
              <br />
              <br />
              Acato B.V., een besloten vennootschap met beperkte aansprakelijkheid naar Nederlands recht, gevestigd en kantoorhoudende aan de Veenkade 48, (2513EH) Den-Haag
              <br />
              <br />
              ("Verwerker");
              <br />
              hierna gezamenlijk tevens te noemen "Partijen" en ieder afzonderlijk "Partij".
              <br />
              <br />
              Overwegingen:
              <ol>
              <li>Verantwoordelijke met Verwerker één of meerdere onderliggende overeenkomsten heeft op basis waarvan Verwerker ten behoeve van Verantwoordelijke diensten levert met betrekking tot onderhoud en beheer van de website en/of andere online gerelateerde diensten (“Verwerkersovereenkomst”);</li>
              <li>Verwerker in het kader van de Onderliggende Overeenkomst in opdracht van Verwerkingsverantwoordelijke persoonsgegevens verwerkt en Verwerker en Verwerkingsverantwoordelijke in dat verband optreden als ‘verwerker’ respectievelijk ‘verwerkingsverantwoordelijke’ in de zin van de Privacywetgeving;</li>
              <li>De Verwerkingsverantwoordelijke heeft op grond van de toepasselijke Privacywetgeving de plicht, ervoor zorg te dragen dat Verwerker voldoende waarborgen biedt ten aanzien van de Verwerking en beveiliging van persoonsgegevens die door Verwerker worden Verwerkt in opdracht van de Verwerkingsverantwoordelijke;</li>
              <li>Verwerkingsverantwoordelijke en Verwerker zijn een overeenkomst aangegaan voor het verrichten van de volgende diensten zoals beschreven in (A), die ertoe leidt dat Verwerker in opdracht van Verwerkingsverantwoordelijke Persoonsgegevens verwerkt;</li>
              <li>Verwerkingsverantwoordelijke en Verwerker wensen in deze verwerkersovereenkomst de wederzijdse rechten en verplichtingen voor de Verwerking van Persoonsgegevens door Verwerker vast te leggen overeenkomstig de toepasselijke Privacywetgeving;</li>
              <li>Deze Overeenkomst vormt een aanvulling op de Onderliggende Overeenkomst. Bij tegenspraak tussen bepalingen uit deze Overeenkomst en de Onderliggende Overeenkomst prevaleren de bepalingen uit deze Overeenkomst.</li>
              </ol>
              <br />
              Partijen zijn het volgende overeengekomen:
              <br />

              1. DEFINITIES <br />
              De onderstaande gedefinieerde termen hebben in deze Overeenkomst de navolgende betekenis.

              <ul>
                <li>
                  AVG: Verordening (EU) 2016/679 van het Europees Parlement en de Raad van 27 april 2016 betreffende de bescherming van natuurlijke personen in verband met de verwerking van persoonsgegevens en betreffende het vrije verkeer van die gegevens en tot intrekking van Richtlijn 95/46/EG (algemene verordening gegevensbescherming);
                </li>

                <li>
                  Betrokkene: degene op wie een Persoonsgegeven betrekking heeft, waarbij de categorieën van betrokkenen zijn gespecificeerd in Bijlage 1;
                </li>

                <li>
                  Onderliggende Overeenkomst: zoals gedefinieerd in overweging (A);
                </li>

                <li>
                  Overeenkomst: deze verwerkersovereenkomst;
                </li>

                <li>
                  Persoonsgegevens: elk gegeven betreffende een geïdentificeerde of identificeerbare natuurlijke persoon als bedoeld in de toepasselijke Privacywetgeving dat Verwerker op grond van de Onderliggende Overeenkomst Verwerkt of dient te Verwerken, waarvan de soort persoonsgegevens nader zijn gespecificeerd in Bijlage 1;
                </li>

                <li>
                  Privacywetgeving: Alle toepasselijke wet- en regelgeving met betrekking tot de Verwerking en bescherming van persoonsgegevens, waaronder maar niet beperkt tot de AVG en de Uitvoeringswet AVG;
                </li>

                <li>
                  Verwerken / Verwerking: alle handelingen of reeks handelingen uitgevoerd met betrekking tot Persoonsgegevens of een geheel van Persoonsgegevens, al dan niet uitgevoerd via geautomatiseerde procedés, zoals het verzamelen, vastleggen, ordenen, structureren, opslaan, bijwerken of wijzigen, opvragen, raadplegen, gebruiken, verstrekken door middel van doorzending, verspreiden of op andere wijze ter beschikking stellen, aligneren of combineren, afschermen, wissen of vernietigen van Persoonsgegevens;
                </li>

                <li>
                  Verwerker degene die t.b.v. de verantwoordelijke persoonsgegevens verwerkt, zonder aan zijn rechtstreekse gezag te zijn onderworpen;
                </li>

                <li>
                  Verwerkingsverantwoordelijke de natuurlijke persoon, rechtspersoon of ieder ander die het doel van de middelen voor de verwerking van persoonsgegevens vaststelt;
                </li>
              </ul>

              2. TOEPASSELIJKHEID <br />
              <ol>
                <li>Tenzij Partijen anders schriftelijk zijn overeengekomen, zijn de bepalingen van deze Overeenkomst van toepassing op iedere Verwerking door Verwerker op grond van de Onderliggende Overeenkomst.</li>
              </ol>


              3. VERWERKING DOOR VERWERKER <br />
              <ol>
                <li>Verwerker verwerkt de Persoonsgegevens uitsluitend in opdracht van Verwerkingsverantwoordelijke, behoudens afwijkende wettelijke verplichtingen.</li>
                <li>Verwerker verwerkt gegevens ten behoeve van Verwerkingsverantwoordelijke, overeenkomstig diens schriftelijke instructies en onder diens verantwoordelijkheid en op de wijze vastgelegd in de Onderliggende Overeenkomst.</li>
                <li>Verwerker mag de Persoonsgegevens enkel buiten de Europese Economische Ruimte verwerken met voorafgaande schriftelijke toestemming van de Verwerkingsverantwoordelijke. Verwerkingsverantwoordelijk geeft hierbij toestemming aan Verwerker om Persoonsgegevens te verwerken in de landen buiten de Europese Economische Ruimte zoals vermeld in Bijlage 1. Een dergelijke doorgifte en Verwerking van Persoonsgegevens zal geschieden in overeenstemming met de toepasselijke Privacywetgeving.</li>
                <li>
                  Verwerker zal geen Persoonsgegevens aan een derde verstrekken of ter beschikking stellen tenzij:
                  <ol>
                    <li>op grond van een uitdrukkelijke schriftelijke opdracht van Verwerkingsverantwoordelijke;</li>
                    <li>op bevel van een gerechtelijke of bestuurlijke instantie;</li>
                    <li>deze op grond van een wettelijke verplichting aan een daartoe bevoegde instantie dienen te worden verstrekt.</li>
                  </ol>
                </li>
                <li>Verwerker zal in dat geval Verwerkingsverantwoordelijke binnen een redelijke termijn na ontvangst van een dergelijk bevel of kennisneming van de wettelijke verplichting daarvan in kennis stellen.</li>
                <li>Verwerker dient Verwerkingsverantwoordelijke binnen een redelijke termijn in kennis te stellen van alle verzoeken met betrekking tot Persoonsgegevens die rechtstreeks van een Betrokkene zijn ontvangen, waaronder maar niet beperkt tot verzoeken tot inzage, rectificatie, wissing, beperking van de verwerking of overdracht van de Persoonsgegevens. Verwerker geeft aan een dergelijk verzoek alleen gevolg indien Verwerkingsverantwoordelijke Verwerker daartoe schriftelijk opdracht heeft gegeven. Verwerker zal de Verwerkingsverantwoordelijke door middel van passende technische en organisatorische maatregelen op haar schriftelijk verzoek haar redelijke medewerking verlenen bij het vervullen van diens plicht om verzoeken om uitoefening van de vastgestelde rechten van de Betrokkenen te beantwoorden waarbij Verwerker gerechtigd is de redelijke kosten in dat verband in rekening te brengen bij Verwerkingsverantwoordelijke indien het verzoek ongegrond of buitensporig is.</li>
                <li>Verwerker stelt op schriftelijk verzoek van Verwerkingsverantwoordelijke de informatie ter beschikking aan Verwerkingsverantwoordelijke die redelijkerwijs nodig is voor Verwerkingsverantwoordelijke om de nakoming van de verplichtingen van Verwerkingsverantwoordelijke als neergelegd in artikel 28 AVG aan te tonen, met ingang van 25 mei 2018.</li>
                <li>Verwerker stelt de Verwerkingsverantwoordelijke zo spoedig als redelijkerwijs mogelijk in kennis indien een instructie naar het redelijk oordeel van Verwerker inbreuk oplevert op de toepasselijke Privacywetgeving.</li>
                <li>Verwerker mag uitsluitend na voorafgaande schriftelijke toestemming van Verwerkingsverantwoordelijke een derde inschakelen bij de uitvoering van deze Overeenkomst, onder de voorwaarden die Verwerkingsverantwoordelijke daarbij stelt. Verwerkingsverantwoordelijk geeft hierbij toestemming aan Verwerker om de in Bijlage 2 omschreven derden in te schakelen bij de uitvoering van deze Overeenkomst. In het geval van een beoogde verandering inzake de toevoeging of vervanging van door de Verwerker ingeschakelde derde zoals vermeld in Bijlage 2, licht Verwerker de Verwerkingsverantwoordelijke hierover vooraf in, waarbij de Verwerkingsverantwoordelijke de mogelijkheid wordt geboden om tegen deze verandering in redelijkheid en op goede gronden bezwaar te maken.</li>
                <li>Verwerker zal de Verwerkingsverantwoordelijke redelijke medewerking verlenen bij het doen nakomen van de verplichtingen uit hoofde van de artikelen 32 tot en met 36 van de AVG waarbij Verwerker gerechtigd is de redelijke kosten in dat verband in rekening te brengen bij Verwerkingsverantwoordelijke.</li>
              </ol>

              4. MELDPLICHT DATALEKKEN <br />
              <ol>
                <li>Verwerker dient Verwerkingsverantwoordelijke, onverwijld en in ieder geval uiterlijk binnen 48 uur nadat Verwerker ervan kennis heeft gekregen, in kennis te stellen van iedere inbreuk op de beveiliging (van welke aard dan ook) die (mede) betrekking heeft of kan hebben op de Verwerking van Persoonsgegevens. De Verwerker voorziet de Verwerkingsverantwoordelijke daarbij van alle, redelijkerwijs benodigde informatie, waaronder in ieder geval begrepen de informatie als bedoeld in artikel 33 lid 3 AVG, om de Verwerkingsverantwoordelijke in staat te stellen zulks – indien vereist op grond van artikel 33 en 34 AVG – tijdig, juist en volledig te melden aan de Autoriteit Persoonsgegevens en de relevante Betrokkenen.</li>
              </ol>

              5. BEVEILIGINGSMAATREGELEN EN INSPECTIE <br />
              <ol>
                <li>Verwerker zal passende technische en organisatorische maatregelen te hebben genomen om Persoonsgegevens te beveiligen tegen verlies of enige vorm van onrechtmatige Verwerking conform het bepaalde in artikel 32 AVG.</li>
                <li>Alle kosten van Verwerkingsverantwoordelijke en Verwerker in verband met de Inspectie worden gedragen door Verwerkingsverantwoordelijke.</li>
                <li>Verwerkingsverantwoordelijke zal Verwerker een exemplaar van het rapport van de Inspectie verstrekken.</li>
              </ol>

              6. VERPLICHTINGEN VERWERKINGSVERANTWOORDELIJKE <br />
              <ol>
                <li>Verwerkingsverantwoordelijke is ten aanzien van de Verwerking van Persoonsgegevens krachtens deze Overeenkomst de 'Verwerkingsverantwoordelijke´.</li>
                <li>Verwerkingsverantwoordelijke gaat ermee akkoord en staat ervoor in dat Verwerker gerechtigd is de Persoonsgegevens te Verwerken en dat de Verwerking van de Persoonsgegevens in overeenstemming is met toepasselijke Privacywetgeving.</li>
              </ol>

              7. AANSPRAKELIJKHEID <br />
              <ol>
                <li>Wij zijn tegenover u aansprakelijk voor schade (inclusief redelijke kosten) die het directe gevolg is van (i) het door ons toerekenbaar tekortschieten in onze verplichtingen op grond van deze Overeenkomst voor zover daarbij reeds sprake is van verzuim, of (ii) een door ons gepleegde onrechtmatige daad, zoals ook u tegenover ons daarvoor op eenzelfde wijze aansprakelijk ben. Partijen zijn niet tegenover elkaar aansprakelijk voor indirecte schade, waaronder (maar niet beperkt tot) gederfde omzet of winst.</li>
                <li>Onze aansprakelijkheid uit hoofde van deze Overeenkomst valt onder dezelfde beperkingen als welke van toepassing zijn op de Opdracht in zijn algemeen.</li>
                <li>Indien wij door een betrokkene rechtstreeks worden aangesproken op een door hem of haar geleden schade verbandhoudende met omstandigheden die aan u zijn toe te rekenen, dan zal u ons tegen een dergelijke vordering vrijwaren.</li>
                <li>Verwerkingsverantwoordelijke vrijwaart Verwerker tegen aanspraken van derden, waaronder Betrokkenen, in verband met het toerekenbaar tekortschieten van Verwerkingsverantwoordelijke in de nakoming van de Overeenkomst of overtreding door Verwerkingsverantwoordelijke van de toepasselijke Privacywetgeving en zal alle daarmee verband houdende en daaruit voortvloeiende kosten (waaronder mede begrepen kosten van juridische bijstand) en schade van Verwerker vergoeden.</li>
              </ol>

              8. GEHEIMHOUDING <br />
              <ol>
                <li>Verwerker heeft haar medewerkers verplicht, behoudens dwingendrechtelijke verplichtingen, tot geheimhouding van de Persoonsgegevens waarvan zij uit hoofde van de verbintenis met Verwerkingsverantwoordelijke kennisnemen.</li>
                <li>Verwerker zal de Persoonsgegevens uitsluitend openbaren aan die medewerkers en/of derden die redelijkerwijs van de Persoonsgegevens kennis dienen te nemen.</li>
              </ol>

              9. BEËINDIGING <br />
              <ol>
                <li>De Overeenkomst is met ingang van de ingangsdatum van de Onderliggende Overeenkomst voor onbepaalde tijd aangegaan en eindigt op het tijdstip dat de Onderliggende Overeenkomst eindigt. Indien geen Onderliggende Overeenkomst is gesloten geldt als ingangsdatum de datum van ondertekening van deze Overeenkomst.</li>
                <li>Tussentijdse opzegging van deze Overeenkomst is niet mogelijk.</li>
                <li>Onverminderd andersluidende schriftelijke opdracht van Verwerkingsverantwoordelijke zal Verwerker in geval van beëindiging van de Overeenkomst en binnen een redelijke termijn na beëindiging van de Overeenkomst, alle aan haar ter beschikking gestelde Persoonsgegevens aan Verwerkingsverantwoordelijke retourneren of vernietigen, zulks naar keuze van de Verwerker, tenzij er naar het redelijk oordeel van Verwerker een zelfstandige wettelijke verplichting bestaat voor Verwerker die het geheel of gedeeltelijk retourneren of vernietigen van de Persoonsgegevens door Verwerker verbiedt of beperkt.</li>
                <li>Verplichtingen uit deze Overeenkomst welke naar hun aard bestemd zijn om ook na het einde van deze Overeenkomst voort te duren, blijven na het einde van deze Overeenkomst bestaan. Tot deze verplichtingen behoren de bepalingen over de geheimhouding van de Persoonsgegevens, toepasselijk recht en geschillenbeslechting.</li>
              </ol>

              10. OVERDRACHT RECHTEN EN PLICHTEN <br />
              <ol>
                <li>Deze Overeenkomst en de rechten en verplichtingen uit deze Overeenkomst kunnen door iedere Partij niet aan derden worden overgedragen zonder de voorafgaande schriftelijke toestemming van de andere Partij.</li>
              </ol>

              11. DEELBAARHEID <br />
              <ol>
                <li>Indien één of meer bepalingen van deze Overeenkomst niet rechtsgeldig blijkt te zijn, zal de Overeenkomst voor het overige van kracht blijven. Partijen zullen over de bepalingen welke niet rechtsgeldig zijn overleg plegen, teneinde een vervangende regeling te treffen die wel rechtsgeldig is en zoveel mogelijk aansluit bij de strekking van de te vervangen regeling.</li>
              </ol>

              12. TOEPASSELIJK RECHT EN GESCHILLEN <br />
              <ol>
                <li>Nederlands recht is van toepassing op deze Overeenkomst.</li>
                <li>Alle geschillen voortvloeiende uit of samenhangende met deze Overeenkomst zullen worden voorgelegd aan de bevoegde rechter te Den Haag.</li>
              </ol>

              <p>Aldus overeengekomen en in tweevoud ondertekend.</p>
            </AcTypography>
            {/* eslint-enable max-len */}
            {/* eslint-enable react/no-unescaped-entities */}

            <AcGridContainer
              fullWidth
              columns={{ xs: 2 }}>
              <span>
                <AcTypography weight="bold">{LABELS.ACATO_BV}</AcTypography>
                <AcTypography>
                  <>
                    {LABELS.SIGNED_BY} Valentijn de Jong <br />
                    {LABELS.ON} {formatDate(documents.single.created_at)} <br />
                    <img
                      src={require('@assets/images/signature.png')}
                      className={styles['ac-document-detail-signature']}
                    />
                  </>
                </AcTypography>
              </span>
              {!!documents.single.signed_at && (
                <span>
                  <AcTypography weight="bold">
                    {documents.single.company.name}
                  </AcTypography>
                  <AcTypography>
                    <>
                      {LABELS.SIGNED_BY}{' '}
                      {formatCustomerContactName(documents.single.signed_by)}
                      <br />
                      {LABELS.ON} {formatDate(documents.single.signed_at)}
                    </>
                  </AcTypography>
                </span>
              )}
            </AcGridContainer>

            <AcContainer padding="none">
              <AcTypography weight="bold">{LABELS.ATTACHMENTS}</AcTypography>
              <AcButton
                onClick={() => downloadAttachment(parseInt(id))}
                icon={ICONS.DOCUMENT}
                iconPosition="before"
                label="bijlagen_verwerkingsovereenkomst.pdf"
                color="secondary"
                mode="dark"
                invertIcon={true}
              />
            </AcContainer>

            {!documents.single.signed_at && (
              <>
                <AcTypography weight="bold">
                  {LABELS.SIGN_AGREEMENT} ({LABELS.REQUIRED.toLowerCase()})
                </AcTypography>
                <AcConfirm
                  id="sign"
                  noteLabel={LABELS.SIGN_REQUIRED}
                  buttonLabel={LABELS.ACCEPT}
                  label={LABELS.SIGN_CONDITIONS}
                  onClick={() => signDocument(parseInt(id))}
                />
              </>
            )}
          </AcPanel>
          <AcContainer padding="none">
            <AcGridContainer columns={{ xs: 1, sm: 2, md: 1 }}>
              <AcPanel>
                <AcTitle level={5}>{LABELS.SUMMARY}</AcTitle>
                <AcDocumentDetails {...documents.single} />
              </AcPanel>
              {account.current_acato_contact && (
                <AcContactCard {...account.current_acato_contact} />
              )}
            </AcGridContainer>
          </AcContainer>
        </AcGridContainer>
      </AcContainer>
    </div>
  )
})
