import { useEffect, useMemo, useRef } from 'react'

import clsx from 'clsx'
import styles from './ac-panel.module.scss'

interface IAcContainer {
  children: React.ReactNode
  className?: string
  fullHeight?: boolean
  colSpan?: 3 | 2 | 1
  onClick?: (e: React.MouseEvent) => void
  element?: 'div' | 'section'
  mode?: 'light' | 'dark'
}

const colSpanToClassName = {
  1: styles['ac-panel--colspan-1'],
  2: styles['ac-panel--colspan-2'],
  3: styles[''],
}

export const AcPanel = ({
  children,
  element: Element = 'div',
  colSpan = 3,
  fullHeight = false,
  onClick,
  className,
  mode = 'dark',
}: IAcContainer) => {
  const acPanelRef = useRef<HTMLDivElement>(null)

  const getPanelClasses = useMemo(
    () =>
      clsx(
        styles['ac-panel'],
        colSpan && colSpanToClassName[colSpan],
        onClick && styles['ac-panel--clickable'],
        fullHeight && styles['ac-panel--full-height'],
        styles[`ac-panel--${mode}`],
        className && className
      ),
    [colSpan, onClick, fullHeight]
  )

  const handleClick = (e: React.MouseEvent) => {
    if (!onClick) return
    e.stopPropagation()
    onClick(e)
  }

  useEffect(() => {
    const acPanelElement = acPanelRef.current
    if (acPanelElement) {
      acPanelElement.onmousemove = (e: MouseEvent) => {
        const rect = acPanelElement.getBoundingClientRect(),
          x = e.clientX - rect.left,
          y = e.clientY - rect.top

        acPanelElement.style.setProperty('--mouse-x', `${x}px`)
        acPanelElement.style.setProperty('--mouse-y', `${y}px`)
      }
    }
  }, [])

  return (
    <Element
      onClick={handleClick}
      ref={acPanelRef}
      className={getPanelClasses}>
      {children}
    </Element>
  )
}
