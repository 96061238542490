import React, { useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import {
  AcGridContainer,
  AcIcon,
  AcPanel,
  AcPanelFooter,
  AcPanelHeader,
  AcPill,
  AcTypography,
} from '@components'
import { IDocument, IDocumentList } from '@typings'
import { formatDate } from '@helpers/format-date.helpers'

interface IDocumentCardProps extends IDocumentList {
  index?: number
}

export const AcDocumentCard = ({
  id,
  title,
  description,
  created_at,
  signed_at,
}: IDocumentCardProps) => {
  const renderTitle = useMemo(() => title ?? null, [title])
  const navigate = useNavigate()

  const handleDocumentClick = (id: IDocument['id']) => {
    return navigate(generatePath(ROUTE_PATHS.DOCUMENT_DETAIL, { id }))
  }

  return (
    <AcPanel onClick={() => handleDocumentClick(id)}>
      <AcIcon icon={ICONS.DOCUMENT} />
      <AcPanelHeader
        panelPreTitleProps={{
          weight: 'bold',
          color: 'primary',
          size: 'xs',
        }}
        panelTitleProps={{
          level: 5,
        }}
        panelPreTitle={`#${id}`}
        panelTitle={renderTitle}
      />
      <AcTypography
        weight="light"
        color="neutral-200">
        {description}
      </AcTypography>

      <AcPanelFooter>
        <AcGridContainer columns={{ sm: 2 }}>
          <AcTypography
            weight="bold"
            color="neutral-200">
            {formatDate(created_at)}
          </AcTypography>
          <AcTypography alignment="right">
            <AcPill size="sm">
              {signed_at ? LABELS.SIGNED : LABELS.PENDING}
            </AcPill>
          </AcTypography>
        </AcGridContainer>
      </AcPanelFooter>
    </AcPanel>
  )
}
