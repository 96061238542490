export const LABELS = {
  ACATO_CLIENT_PORTAL: 'My Acato',
  ACATO_BV: 'Acato B.V.',
  ACCOUNT: 'Account',
  ACCEPT: 'Accepteren',
  ATTACHMENTS: 'Aanvullende documenten',
  BACK: 'Terug',
  BACK_TO_OVERVIEW: 'Terug naar overzicht',
  DEADLINE: 'Deadline',
  COMPANY: 'Bedrijf',
  CHANGES: 'Aanpassingen',
  CONTACT: 'Contact',
  CONTACT_INFO: 'Contactgegevens',
  CUSTOMER_NUMBER: 'Klantnummer',
  DATE_EXPIRY: 'Vervaldatum',
  DATE_QUOTATION: 'Offertedatum',
  DESCRIPTION: 'Omschrijving',
  DOWNLOAD: 'Download',
  DOCUMENT: 'Document',
  DOCUMENTS: 'Documenten',
  EMAIL: 'E-mail',
  FORGOT_PASSWORD: 'Wachtwoord vergeten',
  GENERAL: 'Algemeen',
  GET_IN_TOUCH: 'Neem contact op',
  HOME: 'Home',
  HOUR: 'uur',
  HOURS_TOTAL: 'uur totaal',
  HOURS_OPEN: 'Openstaand',
  NAME: 'Naam',
  NEXT: 'Volgende',
  NOT_SIGNED: 'Niet getekend',
  LOGIN: 'Inloggen',
  LOGOUT: 'Uitloggen',
  ON: 'Op',
  ONSET: 'Aanvang',
  OVERVIEW: 'Overzicht',
  PENDING: 'Openstaand',
  PENDING_ACTIONS: 'Openstaande acties',
  PHONE: 'Telefoon',
  PROJECTS: 'Projecten',
  PREVIOUS: 'Terug',
  PLANNING: 'Planning',
  PLANNED: 'Ingepland',
  REQUIRED: 'Verplicht',
  SENDER: 'Afzender',
  SIGN_REQUIRED: 'Het vinkje is verplicht om te tekenen.',
  SIGN_AGREEMENT: 'Onderteken overeenkomst',
  SIGNED: 'Ondertekend',
  SIGNED_BY: 'Getekend door',
  SUMMARY: 'Samenvatting',
  READ_MORE: 'Lees meer',
  READ_LESS: 'Lees minder',
  WRITTEN: 'Geschreven',
  WRITTEN_HOURS: 'Geschreven uren',
  WITH_ATTENTION_TO: 't.a.v.',
  SIGN_CONDITIONS:
    'Door te accepteren ga ik akkoord en bevestig ik mijn bevoegdheid ' +
    'tot vertegenwoordiging van mijn organisatie.',
} as const

export const DYNAMIC_LABELS = {
  N_HOUR: (number: number) => `${number} uur`,
  HELLO_X: (name: string) => `Hello, ${name}`,
  UNTILL_WEEK_N: (number: number) => `t/m week ${number}`,
  SIGNED_STATUS: (signed: boolean) =>
    signed ? LABELS.SIGNED : LABELS.NOT_SIGNED,
} as const
