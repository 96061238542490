import { AcButton, AcIcon, AcTitle, AcTypography } from '@components'
import { ICONS, LABELS, ROUTE_PATHS } from '@constants'
import { useOnClickOutside } from '@hooks/use-click-outside'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { useNavigate } from 'react-router-dom'
import classes from './ac-app-header-account-menu.module.scss'

export const AcAppHeaderAccountMenu = observer(() => {
  const { account, auth } = useStore()
  const handleLogout = () => {
    auth.logout()
  }
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const wrapperRef = useRef<HTMLSpanElement>()
  const [refElement, setRefElement] = useState<HTMLDivElement | null>(null)
  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(refElement, menuRef, {
    placement: 'bottom-end',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [10, 10],
        },
      },
    ],
  })

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  useOnClickOutside(wrapperRef, () => setMenuOpen(false))

  return (
    <div ref={setRefElement}>
      <span ref={wrapperRef}>
        <AcButton
          onClick={toggleMenu}
          variant="icon-filled"
          mode="dark"
          color="primary"
          label={LABELS.ACCOUNT}
          icon={ICONS.USER}
          padding="none"
        />
        {menuOpen && (
          <div
            ref={setMenuRef}
            style={{ ...styles.popper, opacity: 1 }}
            {...attributes.popper}>
            <div className={classes['ac-app-header-account-menu-element']}>
              <div
                className={classes['ac-app-header-account-menu-element-top']}>
                <div
                  className={
                    classes['ac-app-header-account-menu-element-user']
                  }>
                  <div
                    className={
                      classes['ac-app-header-account-menu-element-user-avatar']
                    }>
                    <AcIcon
                      icon={ICONS.USER}
                      className={
                        classes[
                          'ac-app-header-account-menu-element-user-avatar-icon'
                        ]
                      }
                    />
                  </div>
                  <div
                    className={
                      classes['ac-app-header-account-menu-element-user-info']
                    }>
                    <AcTitle
                      level={6}
                      className={
                        classes['ac-app-header-account-menu-element-user-name']
                      }>
                      {account.current_user_full_name}
                    </AcTitle>
                    <AcTypography
                      className={
                        classes['ac-app-header-account-menu-element-user-email']
                      }
                      element="span"
                      size="xs"
                      weight="light">
                      {account.current_user.email}
                    </AcTypography>
                  </div>
                </div>
              </div>
              <div
                className={
                  classes['ac-app-header-account-menu-element-bottom']
                }>
                <ul
                  className={
                    classes['ac-app-header-account-menu-element-bottom-menu']
                  }>
                  <li
                    onClick={() => navigate(ROUTE_PATHS.ACCOUNT)}
                    className={
                      classes[
                        'ac-app-header-account-menu-element-bottom-menu-item'
                      ]
                    }>
                    <AcIcon
                      icon={ICONS.SETTINGS}
                      size="md"
                    />
                    <AcTypography
                      size="sm"
                      element="span"
                      weight="light">
                      {LABELS.ACCOUNT}
                    </AcTypography>
                  </li>
                  <li
                    onClick={handleLogout}
                    className={
                      classes[
                        'ac-app-header-account-menu-element-bottom-menu-item'
                      ]
                    }>
                    <AcIcon
                      icon={ICONS.LOGOUT}
                      size="md"
                    />
                    <AcTypography
                      size="sm"
                      element="span"
                      weight="light">
                      {LABELS.LOGOUT}
                    </AcTypography>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </span>
    </div>
  )
})
