import { AcPanel, AcPanelHeader, AcProjectHours } from '@components'
import { ROUTE_PATHS } from '@constants'
import { IProject, IProjectList } from '@typings'
import { useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import styles from './ac-project-card.module.scss'
import { useColorFromHours } from '@hooks/use-color-from-hours'

interface IProjectCardProps extends IProjectList {
  index?: number
}

export const AcProjectCard = ({
  name,
  hours,
  customer,
  slug,
}: IProjectCardProps) => {
  const getColorVar = useColorFromHours({ ...hours })
  const renderPreTitle = useMemo(() => customer.name ?? null, [customer])
  const renderTitle = useMemo(() => name ?? null, [name])
  const navigate = useNavigate()

  const handleProjectClick = (slug: IProject['slug']) => {
    return navigate(generatePath(ROUTE_PATHS.PROJECT_DETAIL, { slug }))
  }

  return (
    <AcPanel
      fullHeight
      onClick={() => handleProjectClick(slug)}
      className={styles[`ac-project--${getColorVar}`]}>
      <AcPanelHeader
        panelTitle={renderTitle}
        panelPreTitle={renderPreTitle}
      />
      <AcProjectHours
        {...hours}
        color={getColorVar}
      />
    </AcPanel>
  )
}
