import { ReactComponent as AcatoIcon } from './../assets/icons/acato.svg'
import { ReactComponent as AlertIcon } from './../assets/icons/alert.svg'
import { ReactComponent as CheckboxIcon } from './../assets/icons/checkbox.svg'
import { ReactComponent as CheckboxCheckedIcon } from './../assets/icons/checkbox-checked.svg'
import { ReactComponent as HomeIcon } from './../assets/icons/home.svg'
import { ReactComponent as ArchiveIcon } from './../assets/icons/archive.svg'
import { ReactComponent as InvoiceIcon } from './../assets/icons/invoice.svg'
import { ReactComponent as LogoutIcon } from './../assets/icons/logout.svg'
import { ReactComponent as UserIcon } from './../assets/icons/user.svg'
import { ReactComponent as ArrowRight } from './../assets/icons/arrow_right.svg'
import { ReactComponent as ArrowLeft } from './../assets/icons/arrow_left.svg'
import { ReactComponent as Email } from './../assets/icons/email.svg'
import { ReactComponent as Phone } from './../assets/icons/phone.svg'
import { ReactComponent as Calendar } from './../assets/icons/calendar.svg'
import { ReactComponent as CalendarCancel } from './../assets/icons/calendar_canceled.svg'
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg'
import { ReactComponent as Company } from './../assets/icons/company.svg'
import { ReactComponent as DocumentIcon } from './../assets/icons/document.svg'
import { ReactComponent as DownloadIcon } from './../assets/icons/download.svg'

/* ---- ICONS ---- */

/* Whenever you want to use an Icon, we use the <AcIcon /> component.
We can pass an Icon prop to it. The prop should be ICONS.<key_of_icon>.
Within the AcIcon component we map the icon string to the correct
icon from ICON_ELEMENTS. */

export const ICON_ELEMENTS = {
  ACATO: AcatoIcon,
  HOME: HomeIcon,
  ARCHIVE: ArchiveIcon,
  INVOICE: InvoiceIcon,
  LOGOUT: LogoutIcon,
  USER: UserIcon,
  ARROW_RIGHT: ArrowRight,
  ARROW_LEFT: ArrowLeft,
  EMAIL: Email,
  PHONE: Phone,
  SETTINGS: SettingsIcon,
  CALENDAR: Calendar,
  CALENDARCANCEL: CalendarCancel,
  COMPANY: Company,
  DOCUMENT: DocumentIcon,
  DOWNLOAD: DownloadIcon,
  ALERT: AlertIcon,
  CHECKBOX: CheckboxIcon,
  CHECKBOX_CHECKED: CheckboxCheckedIcon,
} as const

export const ICONS = {
  ACATO: 'ACATO',
  HOME: 'HOME',
  ARCHIVE: 'ARCHIVE',
  INVOICE: 'INVOICE',
  LOGOUT: 'LOGOUT',
  USER: 'USER',
  ARROW_LEFT: 'ARROW_LEFT',
  ARROW_RIGHT: 'ARROW_RIGHT',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  CALENDAR: 'CALENDAR',
  CALENDARCANCEL: 'CALENDARCANCEL',
  COMPANY: 'COMPANY',
  SETTINGS: 'SETTINGS',
  DOCUMENT: 'DOCUMENT',
  DOWNLOAD: 'DOWNLOAD',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_CHECKED: 'CHECKBOX_CHECKED',
  ALERT: 'ALERT',
} as const
