import styles from './ac-pill.module.scss'

import React from 'react'
import clsx from 'clsx'

interface IAcPill {
  children: React.ReactNode
  size?: 'sm'
}

export const AcPill = ({ children, size }: IAcPill) => {
  const classes = clsx(styles['ac-pill'], size && styles[`ac-pill--${size}`])

  return <span className={classes}>{children}</span>
}
