import { IProject } from '@typings'
import { useMemo } from 'react'

export const useColorFromHours = ({
  written,
  planned,
  total_available,
}: IProject['hours']) => {
  const getColorVar = useMemo(() => {
    if (written > total_available || planned + written > total_available) {
      return 'secondary'
    } else {
      return 'primary'
    }
  }, [written, planned, total_available])

  return getColorVar
}
