import { Outlet } from 'react-router-dom'
import styles from './ac-layout-guest.module.scss'

export const AcLayoutGuest = () => {
  return (
    <main className={styles['ac-layout-guest']}>
      <div className={styles['ac-layout-guest__content']}>
        <Outlet />
      </div>
    </main>
  )
}
