import { AccountApi } from './account.api'
import { AuthApi } from './auth.api'
import { ProjectsApi } from './projects.api'
import { DocumentsApi } from './documents.api'
export { BaseApi } from './_base.api'

export interface IApi {
  documents: DocumentsApi
  auth: AuthApi
  projects: ProjectsApi
  account: AccountApi
}

export default (): IApi => {
  const auth = new AuthApi()
  const projects = new ProjectsApi()
  const account = new AccountApi()
  const documents = new DocumentsApi()

  return { projects, account, auth, documents }
}
