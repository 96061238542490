import { AcIcon } from '@components'
import { LABELS } from '@constants'
import { formatDate } from '@helpers/format-date.helpers'
import { IProject } from '@typings'
import styles from './ac-project-general.module.scss'

export const AcProjectGeneral = ({
  start_date,
  end_date,
  customer,
  customer_contact,
}: IProject) => {
  return (
    <ul className={styles['ac-project-general']}>
      <li>
        <strong>
          <AcIcon
            icon="CALENDAR"
            size="md"
          />{' '}
          {LABELS.ONSET}
        </strong>
        {start_date && formatDate(start_date)}
      </li>
      <li>
        <strong>
          <AcIcon
            icon="CALENDARCANCEL"
            size="md"
          />{' '}
          {LABELS.DEADLINE}
        </strong>
        {end_date && formatDate(end_date)}
      </li>
      <li>
        <strong>
          <AcIcon
            icon="COMPANY"
            size="md"
          />{' '}
          {LABELS.COMPANY}
        </strong>
        {customer && customer.name}
      </li>
      <li>
        <strong>
          <AcIcon
            icon="USER"
            size="md"
          />{' '}
          {LABELS.CONTACT}
        </strong>
        {customer_contact && customer_contact.name}
      </li>
    </ul>
  )
}
