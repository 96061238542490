import { useMemo } from 'react'
import { AcTypography } from '../index.core.component'
import styles from './ac-input.module.scss'
import clsx from 'clsx'

interface IAcInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string
  label?: string
  hint?: string
  error?: string
}

export const AcInput = ({
  onChange,
  value,
  label,
  hint,
  error,
  ...rest
}: IAcInputProps) => {
  const getInputClasses = useMemo(
    () =>
      clsx(
        styles['ac-input'],
        error && styles['ac-input--has-error'],
        (hint || error) && styles['ac-input--space-bottom']
      ),
    [error, hint]
  )
  return (
    <span className={getInputClasses}>
      {label && (
        <AcTypography
          className={styles['ac-input-label']}
          size="sm"
          weight="semibold"
          element="span">
          {label}
        </AcTypography>
      )}
      <input
        {...rest}
        className={styles['ac-input-element']}
        onChange={onChange}
        value={value}
      />
      {(hint || error) && (
        <AcTypography
          className={styles['ac-input-hint']}
          size="xs"
          element="span">
          {error ? error : hint}
        </AcTypography>
      )}
    </span>
  )
}
