import React, { useState } from 'react'
import styles from './ac-checkbox.module.scss'
import clsx from 'clsx'

interface IAcCheckbox {
  id: string
  label: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const AcCheckbox = ({ id, label, onChange }: IAcCheckbox) => {
  const [checked, setChecked] = useState(false)
  const classes = clsx(styles['ac-checkbox'])

  const onChangeHandler = event => {
    setChecked(event.target.checked)
    onChange(event)
  }

  return (
    <label
      htmlFor={id}
      className={classes}>
      {label}
      <input
        className={styles['ac-checkbox__input']}
        id={id}
        type="checkbox"
        checked={checked}
        onChange={onChangeHandler}
      />
      <span className={styles['ac-checkbox__indicator']}></span>
    </label>
  )
}
