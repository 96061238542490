/* eslint-disable max-len */
import { STORES, STORE_KEYS } from '@constants'
import { Store } from '@stores'
import { action, computed, makeAutoObservable } from 'mobx'
import { IDocument, IDocumentList } from '@typings'

const defaultValues = {
  loading: true,
  all: [],
  single: {} as IDocument,
}

export class DocumentStore {
  [key: string]: unknown

  store: Store
  loading: boolean
  all: IDocumentList[]
  single: IDocument

  constructor(store: Store) {
    this.store = store
    this.loading = defaultValues.loading
    this.all = defaultValues.all
    this.single = defaultValues.single
    makeAutoObservable(this)
  }

  @computed
  get hasDocuments(): boolean {
    return this.all && !!this.all.length
  }

  @computed
  get getPendingDocuments(): Array<IDocumentList> {
    if (!this.hasDocuments) {
      return []
    }

    return this.all.filter(document => !document.signed_at)
  }

  @action
  getList = async () => {
    this.store.set(STORES.DOCUMENTS, STORE_KEYS.LOADING, true)
    await this.store.api.documents
      .getList()
      .then(res => {
        this.store.set(STORES.DOCUMENTS, STORE_KEYS.ALL, res.data)
      })
      .catch(e => console.error(e))
      .finally(() => {
        this.store.set(STORES.DOCUMENTS, STORE_KEYS.LOADING, false)
      })
  }

  @action
  getSingle = async (id: IDocument['id']) => {
    this.store.set(STORES.DOCUMENTS, STORE_KEYS.LOADING, true)
    await this.store.api.documents
      .getDetail(id)
      .then(res => {
        this.store.set(STORES.DOCUMENTS, STORE_KEYS.SINGLE, res.data)
      })
      .catch(e => console.error(e))
      .finally(() => {
        this.store.set(STORES.DOCUMENTS, STORE_KEYS.LOADING, false)
      })
  }

  @action
  sign = async (id: IDocument['id']) => {
    await this.store.api.documents.sign(id).then(res => {
      this.getSingle(id)
      return res.data
    })
  }

  @action
  downloadDocument = async (id: IDocument['id']) => {
    await this.store.api.documents.downloadDocument(id).then(res => {
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'verwerkingsovereenkomst.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  @action
  downloadAttachment = async (id: IDocument['id']) => {
    await this.store.api.documents.downloadAttachment(id).then(res => {
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'verwerkingsovereenkomst_bijlagen.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  @action
  resetOverview = () => {
    const resetFields = [STORE_KEYS.LOADING, STORE_KEYS.ALL]

    for (const field of resetFields) {
      this.store.set(STORES.DOCUMENTS, field, defaultValues[field])
    }
  }

  @action
  resetSingle = () => {
    const resetFields = [STORE_KEYS.LOADING, STORE_KEYS.SINGLE]

    for (const field of resetFields) {
      this.store.set(STORES.DOCUMENTS, field, defaultValues[field])
    }
  }
}
