import { AcNavigation } from '@components'
import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom'
import styles from './ac-layout-authenticated.module.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '@hooks/use-store'
import { ROUTE_PATHS } from '@constants'

export const AcLayoutAuthenticated = observer(() => {
  const { auth } = useStore()

  if (!auth.is_authorized) return <Navigate to={ROUTE_PATHS.LOGIN} />
  return (
    <main className={styles['ac-layout-authenticated']}>
      <div className={styles['ac-layout-authenticated__content']}>
        <Outlet />
      </div>
      <AcNavigation />
      <ScrollRestoration />
    </main>
  )
})
