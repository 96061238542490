import { ROUTE_PATHS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { Navigate, useLocation } from 'react-router-dom'

interface IAcRequireAuthProps {
  children: JSX.Element
  allowed: string[]
}

export const AcRequireAuth = observer(({ children }: IAcRequireAuthProps) => {
  const { auth } = useStore()

  const location = useLocation()

  // const userIsAllowed = () => {
  //   // allowed.filter(singlePermission =>
  //   //   auth?.employee?.permissions?.includes(singlePermission)
  //   // )

  //   return true
  // }

  if (!auth.is_authorized) {
    return (
      <Navigate
        to={ROUTE_PATHS.LOGIN}
        state={{ intended: location.pathname }}
        replace
      />
    )
  }

  return children
})
