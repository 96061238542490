import { ENDPOINTS } from '@constants'
import { IUser } from '@typings'
import { AxiosResponse } from 'axios'
import { BaseApi } from './_base.api'

export class AccountApi extends BaseApi {
  getUser = () =>
    this.api
      .get<AxiosResponse<IUser>>(ENDPOINTS.ACCOUNT.PROFILE)
      .then(response => response.data)
}
