import { KEYS, STORES, STORE_KEYS } from '@constants'
import {
  autoLoad,
  clearAllStorage,
  removeFromStorage,
} from '@helpers/browser-storage.helpers'
import { Store } from '@stores'
import { IAuthRequestTokenData } from '@typings'
import { action, makeAutoObservable } from 'mobx'

interface IAuthDefaultValues {
  [key: string]: unknown
  access_token: Nullable<string>
  loading: boolean
}

const defaultValues: IAuthDefaultValues = {
  access_token: null,
  loading: false,
}

export class AuthStore {
  [key: string]: unknown
  store: Store
  access_token: Nullable<string>
  loading: boolean
  message: string

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.access_token = defaultValues.access_token
    this.loading = false
    this.message = null
    autoLoad(this, STORE_KEYS.ACCESS_TOKEN)
  }

  get is_authorized() {
    return !!this.access_token
  }

  @action
  clearToken = () => {
    removeFromStorage(STORE_KEYS.ACCESS_TOKEN)
  }

  @action
  setToken = async (access_token: string) => {
    await this.store.set(STORES.AUTH, KEYS.ACCESS_TOKEN, access_token, true)
  }
  @action
  requestToken = async (data: IAuthRequestTokenData) => {
    this.store.set(STORES.AUTH, STORE_KEYS.LOADING, true)
    return await this.store.api.auth
      .requestToken(data)
      .then(res => {
        this.setToken(res.data[KEYS.ACCESS_TOKEN])
        this.store.account.getUser()
        return Promise.resolve(res.message)
      })
      .catch(({ response }) => {
        if (response?.data?.message)
          this.store.set(STORES.AUTH, STORE_KEYS.MESSAGE, response.data.message)
        console.error(response)
      })
      .finally(() => {
        this.store.set(STORES.AUTH, STORE_KEYS.LOADING, false)
      })
  }

  @action
  handleInvalidToken = async () => {
    this.store.set(STORES.AUTH, STORE_KEYS.LOADING, true)
    this.store.account.clearUser()
    this.reset()
    this.store.set(STORES.AUTH, STORE_KEYS.LOADING, false)
  }

  @action
  requestEmail = async (email: string) => {
    this.store.set(STORES.AUTH, STORE_KEYS.LOADING, true)
    return await this.store.api.auth
      .requestEmail(email)
      .then(res => {
        if (this.message) this.reset(STORE_KEYS.MESSAGE)
        return Promise.resolve(res.message)
      })
      .catch(e => {
        console.log('error: ', e)
        Promise.reject()
      })
      .finally(() => {
        this.store.set(STORES.AUTH, STORE_KEYS.LOADING, false)
      })
  }

  @action
  logout = async () => {
    this.store.set(STORES.AUTH, STORE_KEYS.LOADING, true)
    return await this.store.api.auth
      .logout()
      .then(() => {
        clearAllStorage()
        this.reset()
      })
      .catch(e => console.error(e))
      .finally(() => {
        this.store.set(STORES.AUTH, STORE_KEYS.LOADING, false)
      })
  }

  reset = action((key?: string) => {
    if (key) {
      removeFromStorage(key)
      this.store.set(STORES.AUTH, key, defaultValues[key])
    } else {
      for (const key in defaultValues) {
        removeFromStorage(key)
        this.store.set(STORES.AUTH, key, defaultValues[key])
      }
    }
  })
}
