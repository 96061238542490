import { AcIcon, AcNavigationItem } from '@components'
import { ICONS, NAVIGATION_MAIN } from '@constants'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import styles from './ac-navigation.module.scss'

export const AcNavigation = observer(() => {
  const renderMenuItems = useMemo(() => {
    return NAVIGATION_MAIN.map(singleItem => (
      <AcNavigationItem
        key={singleItem.to}
        {...singleItem}
      />
    ))
  }, [])

  return (
    <div className={styles['ac-navigation']}>
      <div className={styles['ac-navigation__logo']}>
        <AcIcon
          className={styles['ac-navigation__logo__icon']}
          icon={ICONS.ACATO}
          size="lg"
        />
      </div>

      <nav className={styles['ac-navigation__main']}>{renderMenuItems}</nav>
    </div>
  )
})
