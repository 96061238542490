import React, { useState } from 'react'
import { AcButton } from '../../core/ac-button/ac-button'
import { AcCheckbox } from '../../core/ac-checkbox/ac-checkbox'
import { AcNote } from '../../core/ac-note/ac-note'

type IHandleClick<T> = (
  event: React.MouseEvent<T> | React.KeyboardEvent<T>
) => unknown

interface IAcConfirm {
  onClick?: IHandleClick<HTMLButtonElement>
  buttonLabel: string
  label: string
  noteLabel: string
  id: string
}

export const AcConfirm = ({
  onClick,
  buttonLabel,
  label,
  id,
  noteLabel,
}: IAcConfirm) => {
  const [checked, setChecked] = useState(false)
  const [noteHidden, setNoteHidden] = useState(true)

  const onClickCallback = event => {
    event.preventDefault()
    if (!checked) {
      setNoteHidden(false)
      return
    }

    onClick(event)
  }

  const onCheckboxChange = event => {
    setChecked(event.target.checked)
    setNoteHidden(event.target.checked)
  }

  return (
    <>
      <AcCheckbox
        id={id}
        label={label}
        onChange={event => onCheckboxChange(event)}
      />

      <AcNote
        variant="error"
        content={noteLabel}
        hidden={noteHidden}
      />

      <AcButton
        onClick={onClickCallback}
        label={buttonLabel}
      />
    </>
  )
}
