import { AcEmployee, AcLoader, AcTitle, AcTypography } from '@components'
import { KEYS, LABELS } from '@constants'
import { IProject, IProjectPlanning } from '@typings'
import clsx from 'clsx'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import styles from './ac-project-planning.module.scss'

interface IProjectPlanningProps extends IProjectPlanning {
  loading: boolean
  currentWeek: IProject['week']['current']
}

export const AcProjectPlanning = observer(
  ({ headers, employees, loading, currentWeek }: IProjectPlanningProps) => {
    const getTableHeadClasses = useCallback(
      (currentWeek: number, headWeek: number) =>
        clsx(
          styles['ac-project-planning-table-header'],
          currentWeek === headWeek &&
            styles['ac-project-planning-table-header--active']
        ),
      [currentWeek]
    )
    const renderPlanningHeader = useMemo(() => {
      if (!headers) return null
      return (
        <tr className={styles['ac-project-planning-table-row']}>
          <th
            className={styles['ac-project-planning-table-header']}
            id="name"></th>
          {headers.map(singleHeader => (
            <th
              id={`table-weeknumber-${singleHeader.week_number}`}
              key={singleHeader.week_number}
              className={getTableHeadClasses(
                currentWeek,
                singleHeader.week_number
              )}>
              <AcTypography
                size="sm"
                weight="bold">
                {singleHeader.title}
              </AcTypography>
              <AcTypography
                size="xs"
                color="neutral-200">
                {singleHeader.subtitle}
              </AcTypography>
            </th>
          ))}
        </tr>
      )
    }, [headers, currentWeek])

    const renderPlanningEmployees = useMemo(() => {
      if (!employees || !employees?.length)
        return (
          <tr>
            <td
              colSpan={7}
              className={styles['ac-project-planning-table--empty']}>
              <AcTypography>
                {' '}
                🔍 Er zijn geen geplande werkzaamheden in deze tijdsperiode.
              </AcTypography>
            </td>
          </tr>
        )
      const getColorVariant = (i: number) => {
        if (i % 3 === 0) return KEYS.PRIMARY
        if (i % 3 == 1) return KEYS.TERTIARY
        if (i % 3 === 2) return KEYS.SECONDARY
      }

      return employees.map((singleEmployee, index) => {
        return (
          <tr
            key={singleEmployee.id}
            className={styles['ac-project-planning-table-row']}>
            <td
              className={styles['ac-project-planning-table-data']}
              headers="name">
              <AcEmployee
                name={singleEmployee.name}
                role={singleEmployee.role}
                img={singleEmployee.img}
                color={getColorVariant(index)}
              />
            </td>
            {singleEmployee.data.map((employeeDataItem, i) => {
              const getHourClasses = (i: number) =>
                clsx(
                  styles['ac-project-planning-table-data-hours'],
                  styles[
                    `ac-project-planning-table-data-hours--${getColorVariant(
                      i
                    )}`
                  ]
                )

              return (
                <td
                  headers={`table-weeknumber-${employeeDataItem.week_number}`}
                  key={i}
                  className={`${styles['ac-project-planning-table-data']} ${
                    currentWeek === employeeDataItem.week_number &&
                    styles['ac-project-planning-table-data--active']
                  }`}>
                  {employeeDataItem.planned_hours && (
                    <div className={getHourClasses(index)}>
                      <AcTypography
                        element="span"
                        weight="bold"
                        size="sm">
                        {employeeDataItem.planned_hours}
                        <AcTypography
                          element="span"
                          size="sm">
                          {` ${LABELS.HOUR}`}
                        </AcTypography>
                      </AcTypography>
                    </div>
                  )}
                </td>
              )
            })}
          </tr>
        )
      })
    }, [employees, currentWeek])

    const renderPlanningTable = useMemo(() => {
      return (
        <>
          <table className={styles['ac-project-planning-table']}>
            <thead className={styles['ac-project-planning-table-head']}>
              {renderPlanningHeader}
            </thead>
            <tbody className={styles['ac-project-planning-table-body']}>
              {renderPlanningEmployees}
            </tbody>
          </table>
        </>
      )
    }, [employees, headers, currentWeek])

    return (
      <div className={styles['ac-project-planning']}>
        <div className={styles['ac-project-planning-header']}>
          <AcTitle
            level={5}
            withBottomMargin>
            {LABELS.PLANNING}
          </AcTitle>
        </div>
        {loading ? (
          <AcLoader />
        ) : (
          <div className={styles['ac-project-planning-content']}>
            {renderPlanningTable}
          </div>
        )}
      </div>
    )
  }
)
