import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import styling from './ac-tooltip.module.scss'

export interface IAcTooltip {
  children: React.ReactNode
  label: string
  /** @default 400 */
  delay?: number
  visible?: boolean
}

export const AcTooltip = ({
  children,
  label,
  delay = 400,
  visible = true,
}: IAcTooltip) => {
  let timeout: ReturnType<typeof setTimeout>
  const [active, setActive] = useState<boolean>(false)
  const [refElement, setRefElement] = useState<HTMLSpanElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(refElement, tooltipRef, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
  })

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  if (!visible) return <>{children}</>

  return (
    <span
      ref={setRefElement}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}>
      {children}
      {active &&
        createPortal(
          <div
            style={styles.popper}
            ref={setTooltipRef}
            className={styling['ac-tooltip']}
            {...attributes.popper}>
            {label}
          </div>,
          document.querySelector('#root') as Element
        )}
    </span>
  )
}
