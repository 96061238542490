import { BaseApi } from './_base.api'
import { ENDPOINTS } from '@constants'
import {
  IProject,
  IProjectList,
  IProjectListResponse,
  IProjectPlanning,
} from '@typings'
import { AxiosResponse } from 'axios'

export class ProjectsApi extends BaseApi {
  getList = () =>
    this.api
      .get<AxiosResponse<IProjectListResponse>>(ENDPOINTS.PROJECTS.LIST)
      .then(response => response.data)

  getDetail = (slug: IProjectList['slug']) =>
    this.api
      .get<AxiosResponse<{ data: IProject }>>(ENDPOINTS.PROJECTS.DETAIL(slug))
      .then(response => response.data)

  getDetailPlanning = (slug: IProjectList['slug']) =>
    this.api
      .get<AxiosResponse<{ data: IProjectPlanning }>>(
        ENDPOINTS.PROJECTS.DETAIL_PLANNING(slug)
      )
      .then(response => response.data)
}
