import { KEYS, ROUTES } from '@constants'
import { useStore } from '@hooks/use-store'
import { AcLayoutAuthenticated } from '@layouts'
import { ISingleRoute } from '@typings'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import { AcRequireAuth } from '../index.core.component'

const AcRouter = observer(() => {
  const { auth } = useStore()
  const renderRoutes = useMemo(() => {
    const routeCollection = []

    const generateRouteComponent = ({
      element,
      id,
      path,
      layout,
      allowed,
    }: ISingleRoute) => (
      <Route
        path="/"
        element={layout ? layout : <AcLayoutAuthenticated />}>
        <Route
          key={id}
          id={id}
          element={
            allowed ? (
              <AcRequireAuth allowed={allowed}>
                {element as JSX.Element}
              </AcRequireAuth>
            ) : (
              element
            )
          }
          path={path}
        />
      </Route>
    )

    for (const Route of ROUTES) {
      routeCollection.push(generateRouteComponent(Route))
    }

    return routeCollection
  }, [auth.is_authorized])

  const router = createBrowserRouter(createRoutesFromElements(renderRoutes))

  useEffect(() => {
    window.addEventListener(KEYS.UNAUTH, () => auth.handleInvalidToken())
    window.addEventListener(KEYS.NEW_TOKEN, (e: CustomEvent) => {
      auth.setToken(e.detail.authorization as string)
    })

    return () => {
      window.removeEventListener(KEYS.NEW_TOKEN, (e: CustomEvent) =>
        auth.setToken(e.detail.authorization as string)
      ),
        window.removeEventListener(KEYS.UNAUTH, () => auth.handleInvalidToken())
    }
  }, [])

  return <RouterProvider router={router} />
})

export { AcRouter }
