import { AcAppHeaderAccountMenu, AcTextButton, AcTitle } from '@components'
import { DYNAMIC_LABELS, ICONS, LABELS } from '@constants'
import { useStore } from '@hooks/use-store'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import styles from './ac-app-header.module.scss'

interface IAcAppHeader {
  backActionLabel?: string
  backAction?: () => void
  withGreeting?: boolean
}

export const AcAppHeader = observer(
  ({
    backAction,
    withGreeting = false,
    backActionLabel = LABELS.BACK,
  }: IAcAppHeader) => {
    const { account } = useStore()
    const backLabel = useMemo(() => backActionLabel, [backActionLabel])

    const generateMainGreeting = useMemo(
      () => DYNAMIC_LABELS.HELLO_X(account?.current_user_name),
      [withGreeting, account.user]
    )

    const handleBackAction = () => {
      backAction && backAction()
    }

    return (
      <div className={styles['ac-app-header']}>
        <div className={styles['ac-app-header--top']}>
          <div>
            {!withGreeting && backAction && (
              <AcTextButton
                label={backLabel}
                icon={ICONS.ARROW_LEFT}
                onClick={handleBackAction}
              />
            )}
            {withGreeting && (
              <AcTitle
                level={3}
                className={styles['ac-app-header__greeting']}>
                {generateMainGreeting}
                <span className={styles['ac-app-header__greeting__icon']}>
                  &#128075;
                </span>
              </AcTitle>
            )}
          </div>
          <AcAppHeaderAccountMenu />
        </div>
      </div>
    )
  }
)
