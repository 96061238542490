import clsx from 'clsx'
import { createElement, useMemo } from 'react'
import styles from './ac-title.module.scss'

interface IAcTitle {
  children: React.ReactNode
  level?: 1 | 2 | 3 | 4 | 5 | 6
  className?: string
  withBottomMargin?: boolean
}

export const AcTitle = ({ children, level = 1, className, withBottomMargin = false }: IAcTitle) => {
  const getTitleClassNames = useMemo(
    () =>
      clsx(
        styles['ac-title'],
        styles[`ac-title--${level}`],
        withBottomMargin && styles['ac-title--with-margin'],
        className
      ),
    [level, className]
  )

  const element = useMemo(
    () =>
      createElement(`h${level}`, { className: getTitleClassNames }, children),
    [level, children, getTitleClassNames]
  )

  return element
}
