import { IDocument, IProject } from '@typings'

const PROJECTS = '/projects'
const PLANNED_HOURS = '/planned-hours'
const PROFILE = '/profile'
const AUTH = '/auth'
const TOKEN = '/token'
const EMAIL = '/email'
const LOGOUT = '/logout'
const DOCUMENTS = '/documents'

export const ENDPOINTS = {
  PROJECTS: {
    LIST: `${PROJECTS}`,
    DETAIL: (SLUG: IProject['slug']) => `${PROJECTS}/${SLUG}`,
    DETAIL_PLANNING: (SLUG: IProject['slug']) =>
      `${PROJECTS}/${SLUG}${PLANNED_HOURS}`,
  },
  ACCOUNT: {
    PROFILE: `${PROFILE}`,
  },
  AUTH: {
    TOKEN: `${AUTH}${TOKEN}`,
    EMAIL: `${AUTH}${EMAIL}`,
    LOGOUT: `${AUTH}${LOGOUT}`,
  },
  DOCUMENTS: {
    LIST: `${DOCUMENTS}`,
    SIGN: (ID: IDocument['id']) => `${DOCUMENTS}/${ID}`,
    ATTACHMENT: (ID: IDocument['id']) => `${DOCUMENTS}/${ID}/attachment`,
    DOWNLOAD: (ID: IDocument['id']) => `${DOCUMENTS}/${ID}/download`,
    DETAIL: (ID: IDocument['id']) => `${DOCUMENTS}/${ID}`,
  },
}
