import { AcButton, AcIcon, AcPanel, AcTitle } from '@components'

import { ICONS, LABELS } from '@constants'
import { IUser } from '@typings'
import styles from './ac-contact-card.module.scss'

export const AcContactCard = ({
  img,
  name,
  email,
  phone,
}: IUser['acato_contact']) => {
  return (
    <AcPanel
      className={styles['ac-contact-card']}
      fullHeight>
      <div className={styles['ac-contact-card__person']}>
        <div
          className={styles['ac-contact-card__person__img']}
          style={{ backgroundImage: `url(${img?.md})` }}
        />
        <div className={styles['ac-contact-card__person__info']}>
          <AcTitle
            level={4}
            className={styles['ac-contact-card__person__info-name']}>
            {name}
          </AcTitle>
          <a
            href={`mailto:${email}`}
            className={styles['ac-contact-card__person__info-email']}>
            <AcIcon
              icon={ICONS.EMAIL}
              size="md"
            />
            {email}
          </a>
          <a
            href={`tel:${phone}`}
            className={styles['ac-contact-card__person__info-tel']}>
            <AcIcon
              icon={ICONS.PHONE}
              size="md"
            />
            {phone}
          </a>
        </div>
      </div>
      <div className={styles['ac-contact-card__button-wrapper']}>
        <AcButton
          label={LABELS.GET_IN_TOUCH}
          color="secondary"
          mode="light"
          icon={ICONS.ARROW_RIGHT}
        />
      </div>
    </AcPanel>
  )
}
